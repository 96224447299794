import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'

import {Box, CardActionArea, IconButton, Stack, Typography, useMediaQuery} from "@mui/material";
import CloudIcon from '@mui/icons-material/Cloud';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styled from "@emotion/styled";
import PersonIcon from '@mui/icons-material/Person';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LinkIcon from '@mui/icons-material/Link';

import AppContainer from "../../components/shared/AppContainer";
import AppCard from "../../components/shared/AppCard";
import AnesiLogo from "../../assets/Images/anesi_Logo_small.png";
import {useUser} from "../../contexts/UserProvider";
import {useUnits} from "../../contexts/UnitProvider";
import {
    getCurrentGreeting,
    getWeather,
    getLocation,
    convertTemperature
} from "../../utilities/DashboardUtils";
import DashboardTour from "../../components/dashboard/DashboardTour";

const ClickableCard = styled(AppCard)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#4EB6B5',
    marginTop: '1em',
    cursor: 'pointer',
});

const CardIconContainer = styled(Box)({
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
});

const CardActionAreaStyled = styled(CardActionArea)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: '#4EB6B5',
    borderColor: 'transparent',
});

const SnapshotCard = styled(StyledCard)({
    padding: '0.25em',
    margin: '1em 0',
    backgroundColor: '#fff',
    borderColor: '#37589D',
});

const TilesContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '0.5em',
});

const Tile = styled(Stack)((props) => ({
    padding: '1em',
    backgroundColor: props.bgcolor || '#37589D',
    borderRadius: '8px',
    alignItems: 'flex-start',
}));


function DashboardScreen() {
    const navigate = useNavigate();
    const { user, isContractor } = useUser();
    const {units, fetchAllUnits} = useUnits();
    const [unitStats, setUnitStats] = useState({
        totalServicedUnits: 0,
        functionalUnits: 0,
        unitsWithErrors: 0,
    });

    const [weatherPermissionDenied, setWeatherPermissionDenied] = useState(false);
    const weatherApiKey = '3528bd613e48c32832d13ff157bc940b';
    const [weatherData, setWeatherData] = useState(null);
    const [location, setLocation] = useState(null);


    async function fetchWeatherData() {
        try {
            const position = await getLocation();
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });

            const weather = await getWeather(latitude, longitude, weatherApiKey);
            setWeatherData(weather);
            setWeatherPermissionDenied(false);
        } catch (error) {
            if (error.message.includes('denied')) {
                setWeatherPermissionDenied(true);
            }
        }
    }

    useEffect(() => {
        if (user?.permission_location && !weatherPermissionDenied) {
            fetchWeatherData();
        }
    }, [user?.permission_location, weatherPermissionDenied]);

    // Find a way to stop continuous fetch if no units in list.
    useEffect(() => {
        if (!units || units.length === 0) {
            fetchAllUnits();
        }
    });

    // Find way to not show previous accounts cached results.
    useEffect(() => {
        if (units) {
            calculateUnitStats(units);
        }
    }, [units]);

    const calculateUnitStats = (units) => {
        const totalServicedUnits = units.length;
        const functionalUnits = units.filter(unit => unit.faults.length === 0).length;
        const unitsWithErrors = totalServicedUnits - functionalUnits;
        setUnitStats({
            totalServicedUnits,
            functionalUnits,
            unitsWithErrors,
        });
    };

    return (
        <AppContainer>
            <DashboardTour />
            <Stack direction="row" justifyContent="flex-end" mt={0.5}>
                <IconButton className="my-account-button" onClick={() => navigate('my_account')}>
                    <PersonIcon color="secondary"  />
                </IconButton>
                <IconButton className="settings-button" onClick={() => navigate('settings')}>
                    <SettingsApplicationsIcon color="secondary" />
                </IconButton>
            </Stack>
            <Typography color="secondary" variant="h6" my={1} >{getCurrentGreeting()}, {user.first_name}!</Typography>
            {user.permission_location && !weatherPermissionDenied && (
                <StyledCard>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1} >
                            <CloudIcon sx={{ color: 'white'}} />
                            {weatherData ? (
                                <Typography color="white">
                                    {weatherData.weather[0].description.charAt(0).toUpperCase() + weatherData.weather[0].description.slice(1)}
                                </Typography>
                            ) : (
                                <Typography color="white" fontWeight="bold" variant="body2">Checking in on your city...</Typography>
                            )}
                        </Stack>
                        {weatherData && (
                            <Stack mr={1}>
                                <Typography variant="body2" color="white" fontWeight="bold">
                                    Temp: {convertTemperature(weatherData.main.temp, user?.metric)}
                                </Typography>
                                <Typography variant="body2" color="white" fontWeight="bold">
                                    Humidity: {weatherData.main.humidity}%
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </StyledCard>
            )}
            <ClickableCard className="clickable-card" onClick={() => navigate('my_units')}>
                <CardIconContainer>
                    <LinkIcon color="secondary" />
                </CardIconContainer>
                <CardActionAreaStyled>
                    <Typography fontWeight="bold">
                        {isContractor ? 'Units Database' : 'My Residence'}
                    </Typography>
                </CardActionAreaStyled>
            </ClickableCard>
            {isContractor && (
                <SnapshotCard className="snapshot">
                    <Typography variant="h6" color="secondaryText" mb={1}>
                        My Daily Snapshot
                    </Typography>
                    <TilesContainer>
                        <Tile
                            bgcolor="success.main"
                            component={CardActionArea}
                            onClick={() => navigate('my_units', { state: { filter: 'operational', expandCard: true } })}
                        >
                            <Stack direction="row" spacing={1} alignItems="center" >
                                <CheckIcon sx={{ color: 'white'}} />
                                <Typography color="white">Running</Typography>
                            </Stack>
                            <Typography color="white" fontWeight="bold">{unitStats.functionalUnits}</Typography>
                        </Tile>
                        <Tile
                            bgcolor="error.main"
                            component={CardActionArea}
                            onClick={() => navigate('my_units', { state: { filter: 'inFault', expandCard: true } })}
                        >
                            <Stack direction="row" spacing={1} alignItems="center">
                                <ErrorOutlineIcon sx={{ color: 'white'}} />
                                <Typography color="white">In Fault</Typography>
                            </Stack>
                            <Typography color="white" fontWeight="bold">{unitStats.unitsWithErrors}</Typography>
                        </Tile>
                        <Tile
                            style={{ gridColumn: '1 / -1' }}
                            component={CardActionArea}
                            onClick={() => navigate('my_units', { state: { filter: 'all', expandCard: true } })}
                        >
                            <Typography color="white">Total Serviced Units</Typography>
                            <Typography color="white" fontWeight="bold">{unitStats.totalServicedUnits}</Typography>
                        </Tile>
                    </TilesContainer>

                </SnapshotCard>
            )}

            <a href="https://anesicomfort.com/">
                <Box display="flex" justifyContent="center" my={2} >
                    <img width={'50px'} src={AnesiLogo} alt="Anesi Logo"/>
                </Box>
            </a>

        </AppContainer>
    );
}

export default DashboardScreen;
