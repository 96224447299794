import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LineWobble } from '@uiball/loaders';

const ModelViewer = ({ src, large = false }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const modelViewerRef = useRef(null);

    const handleOnLoad = () => {
        setIsLoaded(true);
    };

    useEffect(() => {
        const modelViewer = modelViewerRef.current;
        modelViewer.addEventListener('load', handleOnLoad);
        return () => {
            modelViewer.removeEventListener('load', handleOnLoad);
        };
    }, []);

    return (
        <Box
            style={{ position: 'relative', width: '100%', height: large ? '30em' : '10em' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <model-viewer
                ref={modelViewerRef}
                src={src}
                exposure="0.5"
                shadow-intensity="0.5"
                shadow-softness="1"
                alt="A 3D model of an outdoor HVAC unit for contractors"
                auto-rotate
                camera-controls
                auto-rotate-center="0.5 0.5 0.5"
                orientation="0 90deg 0"
                onLoad={handleOnLoad}
                style={{
                    width: '100%',
                    zIndex: 0,
                    height: '100%',
                }}
            >
            </model-viewer>
            {!isLoaded && (
                <Box style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <LineWobble size={50} speed={1.5} color='#4EB6B5' />
                    <Typography fontWeight="700">
                        Rendering unit model
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default ModelViewer;

