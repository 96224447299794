import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import { CheckIcon } from '../../assets/Icons/Icons.js';

function OptionItem({ label, selected, onClick, icon }) {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ cursor: 'pointer' }} onClick={onClick}>
            <Stack direction="row" spacing={1} >
                {icon}
                <Typography variant="body2" fontWeight="bold">{label}</Typography>
            </Stack>
            {selected && <CheckIcon color="success" />}
        </Box>
    );
}

export default OptionItem;
