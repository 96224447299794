import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {EditIcon, KeyIcon, UpgradeIcon} from '../../assets/Icons/Icons.js'
import {Box, Typography, IconButton, Input, CircularProgress, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";

import AppHeader from "../../components/shared/AppHeader";
import AppCard from "../../components/shared/AppCard";
import AppButton from "../../components/shared/AppButton";
import AppContainer from "../../components/shared/AppContainer";
import {useUser} from "../../contexts/UserProvider";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import AppSwitch from "../../components/shared/AppSwitch";
import AppDivider from "../../components/shared/AppDivider";
import AppDialog from "../../components/shared/AppDialog";
import {useUnits} from "../../contexts/UnitProvider";
import {requestUpgrade} from "../../services/api/user/userApi";

const StyledInput = styled(Input)({
    color: '#4EB6B5',
    fontSize: 16,
    fontWeight: "700",
    height: '24px',
    '&:before': {
        borderBottom: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
});

const PasswordChangeInput = styled(StyledInput)({
    fontSize: 17,
    width: '100%',
    color: 'black',
    textAlign: 'center',
    '& input': {
        textAlign: 'center',
    },
});

const PasswordContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '.25em',
});

const PasswordChangeContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    gap: '0.50em',
});

const SavePasswordButton = styled(AppButton)({
    marginTop: '1em',
    width: '60%',
    '@media (min-width:768px)': {
        width: '30%',
    },
});

const RelativeCard = styled(AppCard) ({
    position: 'relative',
});

const getRoleLabel = (role) => {
    switch(role) {
        case 1:
            return '201';
        case 2:
            return '301';
        case 3:
            return '401';
        default:
            return 'Admin';
    }
};

function AccountScreen() {
    const {user, isContractor, setUser, logout} = useUser();
    // const {units, fetchAllUnits} = useUnits();
    const alert = useAlert();
    const api = useApi();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        phone_number: user.phone_numbers[0]?.number || '',
        address: user.addresses?.[0] || 'Not available'
    });

    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [permissions, setPermissions] = useState({
        notification: user.phone_numbers[0].enable_alerts || false,
        location: user.permission_location || false,
    });

    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [accessModalOpen, setAccessModalOpen] = useState(false);
    const [passwordMode, setPasswordMode] = useState(false);
    const [firstName, setFirstName] = useState(user?.first_name || '');
    const [lastName, setLastName] = useState(user?.last_name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [originalPhoneNumber, setOriginalPhoneNumber] = useState(user?.phone_numbers[0]?.number || '');
    const [phoneNumbers, setPhoneNumbers] = useState(user?.phone_numbers[0]?.number || '');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [notificationPermission, setNotificationPermission] = useState(user?.phone_numbers[0]?.enable_alerts || false);
    const [cameraPermission, setCameraPermission] = useState(user?.permission_camera || false);
    const [locationPermission, setLocationPermission] = useState(user?.permission_location || false);
    // const [stopTrackingDialogOpen, setStopTrackingDialogOpen] = useState(false); // New state for dialog

    const [address, setAddress] = useState({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_abbreviation: '',
        zipcode: ''
    });

    useEffect(() => {
        setFirstName(user?.first_name || '');
        setLastName(user?.last_name || '');
        setEmail(user?.email || '');
        setOriginalPhoneNumber(user?.phone_numbers[0]?.number || 'None on File');
        setNotificationPermission(user?.phone_numbers[0]?.enable_alerts || false);
        setCameraPermission(user?.permission_camera || false);
        setLocationPermission(user?.permission_location || false);
        if (user?.addresses?.length > 0) {
            setAddress(user.addresses[0]);
        }
    }, [user]);

    const handleToggleChangePassword = () => {
        setPasswordMode(!passwordMode);
    };

    const editAccount = () => {
        if (editing) {
            handleSave();
        } else {
            setEditing(true);
        }
    };

    // May eventually need to update how we manage password creation and change if sensitive data is saved.
    const handleChangePassword = async () => {
        const response = await api.put('/me', {
            old_password: oldPassword,
            new_password: newPassword,
        });
        console.log(response);
        if (response.ok) {
            setOldPassword('');
            setNewPassword('');
            setPasswordMode(false);
            alert('Your password has been updated', 'success');
        } else {
            setOldPassword('');
            setNewPassword('');
            setPasswordMode(false);
            alert('Old password does not match', 'error');
        }
    };

    const handleSave = async () => {
        setSaveLoading(true);
        const response = await api.put('/me', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            old_phone_number: originalPhoneNumber,
            new_phone_number: phoneNumbers,
            address: address
        });
        console.log(address)
        console.log(response);
        if (response.ok) {
            setUser(response.body);
            setOriginalPhoneNumber(phoneNumbers);
            setEditing(false);
            alert('Your account has been updated', 'success', 3);
        } else {
            alert('Could not update account, please try again', 'error');
        }
        setSaveLoading(false)
    };

    const updatePermissions = async (field, value) => {
        let payload;
        if (field === 'enable_alerts') {
            payload = {
                "old_phone_number": originalPhoneNumber,
                "enable_alerts": value
            };
        } else {
            payload = { [field]: value };
        }
        const response = await api.put('/me', payload);
        if (response.ok) {
            if (field === 'enable_alerts') {
                setUser(prevState => ({
                    ...prevState,
                    phone_numbers: [{
                        ...prevState.phone_numbers[0],
                        enable_alerts: value
                    }]
                }));
            } else {
                setUser(prevState => ({
                    ...prevState,
                    [field]: value
                }));
            }
            alert('Your access has been updated', 'success', 3);
        } else {
            alert('Could not update access, please try again', 'error', 3);
        }
    };

    // Move into use Units so that every component automatically gets data on render...
    // useEffect(() => {
    //     const fetchUnits = async () => {
    //         const response = await fetchAllUnits();
    //         if(response.ok) {
    //             if (response.body && response.body.length > 0) {
    //                 console.log(response.body);
    //             }
    //         } else {
    //             console.log("Could not fetch units in accounts page.")
    //         }
    //     };
    //     fetchUnits();
    // }, []);

    const toggleAccessModal = () => {
        setAccessModalOpen(!accessModalOpen);
    }

    const handleAccessConfirm = async () => {
        await requestUpgrade(api)
        setAccessModalOpen(false);
    };

    const handleLogout = async () => {
        setLoading(true);
        const result = await logout();
        if (result === 'ok') {
            navigate('/');
        }
        setLoading(false);
    };

    // const handleStopTrackingConfirm = async () => {
    //     const unitIds = units.map(unit => unit.id);
    //     console.log(unitIds);
    //     const response = await api.put('/sim_card', {
    //         unit_ids: unitIds,
    //         status: true
    //     });
    //     console.log(response);
    //     if (response.ok) {
    //         console.log("Successfully updated tracking status for units:", unitIds);
    //     } else {
    //         console.log("Failed to update tracking status for units:", unitIds);
    //     }
    //
    //     setStopTrackingDialogOpen(false);
    // };


    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard`} title="My Account" rightIcon={editing ? <span style={{ fontSize: '0.7em', marginTop: '0.25em'}}>Save</span> : <EditIcon />} onClickRight={editAccount}/>
            <Box mt={1}>
                <Typography gutterBottom >
                    My Information
                </Typography>
                <RelativeCard type="good">
                    {isContractor && (
                        <Box
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: '0.5em',
                                cursor: 'pointer',
                                backgroundColor: '#4EB6B5',
                                borderBottomLeftRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={toggleAccessModal}
                        >
                            <Typography color="white" variant="body2">
                                Access: {getRoleLabel(user.role)}
                            </Typography>
                            <UpgradeIcon fontSize="small" sx={{ color: 'white' }} />
                        </Box>
                    )}
                    <Typography color="gray" variant="body2">First Name:</Typography>
                    {editing ?
                        <StyledInput
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            disableUnderline
                        /> :
                        <Typography fontWeight="bold">{user.first_name}</Typography>
                    }
                    <Typography color="gray" variant="body2">Last Name:</Typography>
                    {editing ?
                        <StyledInput
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            disableUnderline
                        /> :
                        <Typography fontWeight="bold">{user.last_name}</Typography>
                    }
                    <Typography color="gray" variant="body2">Phone Number:</Typography>
                    {editing ?
                        <StyledInput
                            value={phoneNumbers}
                            onChange={e => {
                                const value = e.target.value;
                                if (/^[0-9]*$/.test(value) && value.length <= 10) {
                                    setPhoneNumbers(value);
                                }
                            }}
                            maxLength={11}
                            disableUnderline
                        /> :
                        <Typography fontWeight="bold">
                            {
                                originalPhoneNumber.length === 10
                                    ? `${originalPhoneNumber.slice(0, 3)}-${originalPhoneNumber.slice(3, 6)}-${originalPhoneNumber.slice(6, 10)}`
                                    : originalPhoneNumber
                            }
                        </Typography>
                    }
                    {isContractor && (
                        <>
                            <Typography color="gray" variant="body2">Employer:</Typography>
                            <Typography fontWeight="bold">{user.company_id.toUpperCase()}</Typography>
                        </>
                    )}
                    {isContractor && (
                        <>
                            <Typography color="gray" variant="body2">Primary Address:</Typography>
                            {editing ? (
                                <>
                                    <Stack direction="row">
                                        <StyledInput
                                            placeholder="Address Line 1"
                                            value={address.address_line_1}
                                            onChange={e => setAddress({ ...address, address_line_1: e.target.value })}
                                            disableUnderline
                                            fullWidth
                                        />
                                        <StyledInput
                                            placeholder="Address Line 2"
                                            value={address.address_line_2}
                                            onChange={e => setAddress({ ...address, address_line_2: e.target.value })}
                                            disableUnderline
                                            fullWidth
                                        />
                                    </Stack>
                                    <Stack direction="row">
                                        <StyledInput
                                            placeholder="City"
                                            value={address.city}
                                            onChange={e => setAddress({ ...address, city: e.target.value })}
                                            disableUnderline
                                            fullWidth
                                        />
                                        <StyledInput
                                            placeholder="State"
                                            value={address.state_abbreviation}
                                            onChange={e => setAddress({ ...address, state_abbreviation: e.target.value })}
                                            disableUnderline
                                            fullWidth
                                        />
                                        <StyledInput
                                            placeholder="Zipcode"
                                            value={address.zipcode}
                                            onChange={e => setAddress({ ...address, zipcode: e.target.value })}
                                            disableUnderline
                                            fullWidth
                                        />
                                    </Stack>
                                </>
                            ) : (
                                <Box>
                                    <Typography component="span" fontWeight="600">{address.address_line_1}, {address.address_line_2}</Typography>
                                    <Typography fontWeight="700">{address.city}, {address.state_abbreviation} {address.zipcode}</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </RelativeCard>
            </Box>
            <Box mt={1}>
                <Typography gutterBottom>
                    Account Information
                </Typography>
                <AppCard>
                    <Typography color="gray" variant="body2">Email address:</Typography>
                    {editing ?
                        <StyledInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            disableUnderline
                        /> :
                        <Typography fontWeight="bold">{user.email}</Typography>
                    }
                    <PasswordContainer>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Typography color="gray" variant="body2">Password:</Typography>
                            <IconButton onClick={handleToggleChangePassword}>
                                <KeyIcon color="secondary" />
                            </IconButton>
                        </Box>
                        {passwordMode ? (
                            <PasswordChangeContainer>
                                <PasswordChangeInput
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                    type="password"
                                    placeholder="Old Password"
                                    disableUnderline
                                />
                                <PasswordChangeInput
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    type="password"
                                    placeholder="New Password"
                                    disableUnderline
                                />
                                <SavePasswordButton onClick={handleChangePassword}>Save Password</SavePasswordButton>
                            </PasswordChangeContainer>
                        ) : (
                            <Box display="flex" alignItems="center" width="100%">
                                <Typography style={{ flexGrow: 1, fontWeight: '700' }} onClick={handleToggleChangePassword}>
                                    Hidden for Privacy
                                </Typography>
                            </Box>
                        )}
                    </PasswordContainer>
                </AppCard>
            </Box>
            <Box mt={1}>
                <Typography gutterBottom>
                    Allow Anesi Access
                </Typography>
                <AppCard>
                    <Box display="flex" justifyContent="space-between">
                        <Box dispaly="flex" flexDirection="column">
                            <Typography fontWeight="700" color="#959292">Notifications</Typography>
                            <Typography color="#959292" fontSize="0.75em">
                                (Choose to enable Email / SMS Messaging)
                            </Typography>
                        </Box>
                        <AppSwitch checked={notificationPermission}
                           onChange={() => {
                               const updatedPermission = !notificationPermission;
                               setNotificationPermission(updatedPermission);
                               updatePermissions('enable_alerts', updatedPermission);
                           }}
                        />
                    </Box>
                    <AppDivider inCard="true"/>
                    <Box display="flex" justifyContent="space-between">
                        <Typography fontWeight="700" color="#959292">Location</Typography>
                        <AppSwitch checked={locationPermission}
                           onChange={() => {
                               const updatedPermission = !locationPermission;
                               setLocationPermission(updatedPermission);
                               updatePermissions('permission_location', updatedPermission);
                           }}
                        />
                    </Box>
                </AppCard>
                {/*<Box display="flex" justifyContent="center" mt={1} onClick={() => setStopTrackingDialogOpen(true)}>*/}
                {/*    <Typography variant="button" color="error">*/}
                {/*        Stop all data collection*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
            </Box>
            <Box mt="auto" mb={1} style={{ position: 'relative' }}>
                <AppButton variant="secondary" onClick={handleLogout} disabled={loading}>
                    Sign Out
                </AppButton>
                {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
            </Box>

            <AppDialog
                open={accessModalOpen}
                title="Request Account Upgrade?"
                handleClose={toggleAccessModal}
                handleConfirm={handleAccessConfirm}
                confirmText="Upgrade"
                cancelText="Cancel"
                icon={<UpgradeIcon fontSize="large" color="success" />}
            >
                <Typography variant="body2">
                    All account upgrades require approval by Anesi. Would you like to proceed?
                </Typography>
            </AppDialog>

            {/*<AppDialog*/}
            {/*    open={stopTrackingDialogOpen}*/}
            {/*    title="Revoke access to data?"*/}
            {/*    handleClose={() => setStopTrackingDialogOpen(false)}*/}
            {/*    handleConfirm={handleStopTrackingConfirm}*/}
            {/*    confirmText="Confirm"*/}
            {/*    cancelText="Cancel"*/}
            {/*>*/}
            {/*    <Typography variant="subtitle2">*/}
            {/*        Are you sure you want to stop the app from tracking your activity? The status of your units will not be shared with our... Get SMTI text...*/}
            {/*    </Typography>*/}
            {/*</AppDialog>*/}

        </AppContainer>

    );
}

export default AccountScreen;
