import React, {useContext, useState, useRef, useEffect} from 'react';
import styled from "@emotion/styled";
import {Typography, TextField, Grid, Stack} from "@mui/material";
import {TextsmsIcon} from '../../assets/Icons/Icons.js';

import AppButton from "../shared/AppButton";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {send2FACode, verify2FACode} from "../../services/api/registration/registrationApi";
import {RegistrationContext} from "../../contexts/RegistrationContext";

const InputContainer = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '1em',
    flexWrap: 'nowrap',
});

const CodeInput = styled(TextField)({
    width: '3em',
    textAlign: 'center',
    '& input': {
        textAlign: 'center',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ced4da',
        },
        '&:hover fieldset': {
            borderColor: '#ced4da',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

function Register2FA(props) {
    const api = useApi();
    const alert = useAlert();
    const { nextStep, formState } = useContext(RegistrationContext);

    const [code, setCode] = useState(Array(6).fill(""));
    const inputs = useRef([]);
    const [loading, setLoading] = useState(false);

    // const send2FACode = async () => {
    //     try {
    //         await api.post('/create_2fa', {
    //             phone_number: formState.phoneNumber
    //         });
    //     } catch (error) {
    //         console.error("Error sending 2FA code:", error);
    //         alert("Failed to send code", "error");
    //     }
    // };

    const sendCode = async () => {
        await send2FACode(api, formState.phoneNumber);
    }

    useEffect(() => {
        sendCode();
    }, []);

    const handleContinue = async () => {
        const verificationCode = code.join("");
        setLoading(true);

        const response = await verify2FACode(api, formState.phoneNumber, verificationCode);
        if (response.ok) {
            const contractorToken = response.body.contractor_token;
            localStorage.setItem("contractorToken", contractorToken);

            alert("Code verified successfully!", "success");
            nextStep();
        } else {
            alert("Invalid code. Please try again.", "error");
        }

        setLoading(false);
    };

    const handleChange = (value, index) => {
        if (isNaN(value)) return;
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value !== "" && index < 5) {
            inputs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            inputs.current[index - 1].focus();
        }
    };

    return (
        <>
            <Stack alignItems="center" spacing={1}>
                <Typography variant="h6">Two Step Verification</Typography>
                <Typography variant="subtitle1" color="secondaryText" textAlign="center">
                    Perfect! We've sent you a code to verify that its really you. Please enter it below.
                </Typography>
            </Stack>
            <InputContainer container spacing={1}>
                {code.map((digit, index) => (
                    <Grid item key={index}>
                        <CodeInput
                            variant="outlined"
                            inputProps={{
                                maxLength: 1,
                                style: { textAlign: 'center' },
                            }}
                            value={digit}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            inputRef={(el) => (inputs.current[index] = el)}
                        />
                    </Grid>
                ))}
            </InputContainer>
            <AppButton variant="primary" onClick={handleContinue}>
                {loading ? "Verifying..." : "Verify"}
            </AppButton>
            <Stack alignItems="center" mt={1} spacing={1}>
                <Typography variant="body2" color="secondaryText" >
                    Didn't receive a code?
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center" onClick={sendCode}>
                    <TextsmsIcon color="secondary" />
                    <Typography variant="subtitle1" fontWeight="bold" color="secondary" >
                        Text New Code
                    </Typography>
                </Stack>
            </Stack>
        </>
    );
}

export default Register2FA;


