import React, {useState} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import {
    ExpandMoreIcon,
    ArrowDropUpIcon,
    EmailIcon,
    SmsIcon,
    WarningIcon,
    ReportIcon,
} from '../../assets/Icons/Icons.js';

import AppContainer from '../../components/shared/AppContainer';
import AppHeader from '../../components/shared/AppHeader';
import AppCard from '../../components/shared/AppCard';
import OptionItem from '../../components/settings/OptionItem';
import { useUser } from '../../contexts/UserProvider';
import { useApi } from '../../contexts/ApiProvider';
import { useAlert } from '../../contexts/AlertsProvider';
import AppDivider from '../../components/shared/AppDivider';
import AppDrawer from "../../components/shared/AppDrawer";

function SettingsScreen(props) {
    const { user, setUser, isContractor } = useUser();
    const api = useApi();
    const alert = useAlert();
    const notificationsEnabled = user.phone_numbers[0]?.enable_alerts;

    const [isMethodExpanded, setMethodExpanded] = useState(false);
    const [isErrorTypesExpanded, setErrorTypesExpanded] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const languageOptions = [
        { label: 'English', value: 0 },
        { label: 'French', value: 1 },
    ];

    const unitOptions = [
        { label: 'Metric', value: true },
        { label: 'Imperial', value: false },
    ];

    const notificationMethods = [
        { label: 'Email', field: 'notify_email', icon: <EmailIcon color="secondary" /> },
        { label: 'SMS', field: 'notify_sms', icon: <SmsIcon color="secondary" /> },
    ];

    const errorTypes = [
        ...(isContractor
            ? [
                {
                    label: 'Warnings',
                    field: 'notify_warning',
                    icon: <WarningIcon color="secondary" />,
                },
            ]
            : []),
        {
            label: 'Lockouts',
            field: 'notify_error',
            icon: <ReportIcon color="secondary" />,
        },
    ];

    const updateUserSettings = async (updates) => {
        const response = await api.put('/me', updates);
        if (response.ok) {
            setUser((prevState) => ({ ...prevState, ...updates }));
            alert('Settings have been updated', 'success');
        } else {
            alert('Could not update settings, please try again', 'error');
        }
    };

    return (
        <AppContainer>
            <AppHeader title="Settings" backButtonRoute={`/dashboard`} />
            <Stack spacing={2} mt={2}>
                {/* Language Selection */}
                <Stack>
                    <Typography gutterBottom>Preferred Language:</Typography>
                    <AppCard type="good">
                        {languageOptions.map((option, index) => (
                            <React.Fragment key={option.value}>
                                {index > 0 && <AppDivider inCard="true" />}
                                <OptionItem
                                    label={option.label}
                                    selected={user.language === option.value}
                                    onClick={() => updateUserSettings({ language: option.value })}
                                />
                            </React.Fragment>
                        ))}
                    </AppCard>
                </Stack>
                {/* Units Selection */}
                <Stack>
                    <Typography gutterBottom>Preferred Units:</Typography>
                    <AppCard type="good">
                        {unitOptions.map((option, index) => (
                            <React.Fragment key={option.label}>
                                {index > 0 && <AppDivider inCard="true" />}
                                <OptionItem
                                    label={option.label}
                                    selected={user.metric === option.value}
                                    onClick={() => updateUserSettings({ metric: option.value })}
                                />
                            </React.Fragment>
                        ))}
                    </AppCard>
                </Stack>
                {/* Notifications */}
                {notificationsEnabled && (
                    <Stack>
                        <Typography gutterBottom >Notifications Settings:</Typography>
                        <AppCard type="good">
                            {/* Methods */}
                            <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                                <CardHeader
                                    title={<Typography >Methods</Typography>}
                                    action={
                                        <ExpandMoreIcon
                                            fontSize="medium"
                                            color="secondary"
                                            style={{ transform: isMethodExpanded ? 'rotate(180deg)' : 'rotate(0deg)', cursor: 'pointer' }}
                                            onClick={() => setMethodExpanded(!isMethodExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isMethodExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {notificationMethods.map((method, index) => (
                                            <React.Fragment key={method.field}>
                                                {index > 0 && <AppDivider inCard="true" />}
                                                <OptionItem
                                                    label={method.label}
                                                    selected={user[method.field]}
                                                    onClick={() => updateUserSettings({ [method.field]: !user[method.field] })}
                                                    icon={method.icon}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </CardContent>
                                    <Typography textAlign="center" variant="body2">
                                        Message and data rates may apply.
                                    </Typography>
                                </Collapse>
                            </Card>
                            {/* Error Types */}
                            <Card variant="outlined" sx={{ marginTop: '0.3em', borderRadius: '8px' }}>
                                <CardHeader
                                    title={<Typography>Notification Types</Typography>}
                                    action={
                                        <ExpandMoreIcon
                                            fontSize="medium"
                                            color="secondary"
                                            style={{ transform: isErrorTypesExpanded ? 'rotate(180deg)' : 'rotate(0deg)', cursor: 'pointer' }}
                                            onClick={() => setErrorTypesExpanded(!isErrorTypesExpanded)}
                                        />
                                    }
                                />
                                <Collapse in={isErrorTypesExpanded} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {errorTypes.map((type, index) => (
                                            <React.Fragment key={type.field}>
                                                {index > 0 && <AppDivider inCard="true" />}
                                                <OptionItem
                                                    label={type.label}
                                                    selected={user[type.field]}
                                                    onClick={() => updateUserSettings({ [type.field]: !user[type.field] })}
                                                    icon={type.icon}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </AppCard>
                        <Stack alignItems="center" direction="row">
                            <Typography color="secondaryText" variant="body2" ml={1}>
                                Learn more
                            </Typography>
                            <IconButton onClick={() => setDrawerOpen(true)} style={{ cursor: 'pointer' }}>
                                <ArrowDropUpIcon color="secondary" />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <Typography textAlign="center" variant="body2" mt="auto">
                Anesi App Version: 1.8.1
            </Typography>

            <AppDrawer title="Notification Types" open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
                <Stack mt={1}>
                    <Typography variant="h6">Hard Lockouts:</Typography>
                    <Typography variant="body2" gutterBottom>
                        Hard lockout notifications inform you when the system is down and will not restart. The unit will require
                        service to restore operation.
                    </Typography>
                    {isContractor && (
                        <>
                            <Typography variant="h6">Soft Lockouts:</Typography>
                            <Typography variant="body2" gutterBottom>
                                Soft lockout notifications inform you when the system has detected a fault condition. The unit will
                                restart after a preset timeframe depending on the fault detected. Repeated warnings indicate service is
                                required.
                            </Typography>
                            <Typography variant="h6">Warnings:</Typography>
                            <Typography variant="body2">
                                Warnings indicate parameters that are out of a specified range. Warnings do not lock out unit operation.
                                Multiple, repeated warnings may indicate service is required.
                            </Typography>
                        </>
                    )}
                </Stack>
            </AppDrawer>

        </AppContainer>
    );
}

export default SettingsScreen;
