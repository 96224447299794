import React from 'react';
import { BottomNavigation, BottomNavigationAction, Badge } from '@mui/material';
import { DescriptionIcon, WarningIcon, AssessmentIcon, HvacIcon } from '../../assets/Icons/Icons.js';
import styled from '@emotion/styled';

const StyledBottomNavigation = styled(BottomNavigation)({
    background: 'linear-gradient(#FCFCFF, #FCFCFF) padding-box, linear-gradient(to right, #37589D, #4EB6B5) border-box',
    borderTopLeftRadius: '1em',
    borderTopRightRadius: '1em',
    borderBottom: 'none',
    borderTop: '2.5px solid transparent',
    height: '5em',
    marginTop: 'auto',
    '& .MuiBottomNavigationAction-label': {
        marginTop: '0.25em',
        fontWeight: '700',
    },
    '& .MuiBottomNavigationAction-root': {
        paddingTop: '0.25em',
    },
});

const StyledBadge = styled(Badge)({
    '& .MuiBadge-dot': {
        width: 15,
        height: 15,
        borderRadius: '50%',
    }
});

const BottomNav = ({ navValue, onChange, hasFault, isContractor }) => (
    <StyledBottomNavigation showLabels value={navValue} onChange={onChange}>
        {isContractor && (
            <BottomNavigationAction
                label="Controller"
                icon={<AssessmentIcon fontSize="large" />}
            />
        )}
        <BottomNavigationAction
            label="Unit"
            icon={<HvacIcon fontSize="large" />}
        />
        <BottomNavigationAction
            label="Documents"
            icon={<DescriptionIcon fontSize="large" />}
        />
        <BottomNavigationAction
            label="Alerts"
            icon={
                <StyledBadge
                    color="error"
                    variant="dot"
                    invisible={!hasFault}
                >
                    <WarningIcon fontSize="large" />
                </StyledBadge>
            }
        />
    </StyledBottomNavigation>
);

export default React.memo(BottomNav);
