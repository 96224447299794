// Update user (owner and contractor) data
export const updateUser = async (apiClient, userData) => {
    try {
        return await apiClient.put('/me', userData);
    } catch (error) {
        console.error('Could not update user:', error);
    }
};

// Update user (owner and contractor) password
export const changePassword = async (apiClient, oldPassword, newPassword) => {
    try {
        return await apiClient.put('/me', {
            old_password: oldPassword,
            new_password: newPassword,
        });
    } catch (error) {
        console.error('Could not change password:', error);
    }

};

export const requestUpgrade = async (apiClient) => {
    try {
        return await apiClient.post('/contractor_upgrade');
    } catch (error) {
        console.error('Could not request upgrade:', error);
    }
}
