import React from 'react';
import { Box, Stack, Typography } from "@mui/material";

import {ReportIcon, AccessTimeFilledIcon, TodayIcon} from '../../assets/Icons/Icons.js';
import AppDivider from "../shared/AppDivider";

function AlertInfo({ type, frequency, occuranceDate, fault }) {

    const infoItems = [
        { label: 'Type:', value: type, icon: <ReportIcon color="error" /> },
        { label: 'Frequency (Monthly):', value: frequency, icon: <AccessTimeFilledIcon color="secondary" /> },
        { label: 'Occurred on:', value: occuranceDate, icon: <TodayIcon color="success" /> },
    ];

    return (
        <Stack padding={2}>
            <Typography variant="h6" textAlign="center" mb={2}>
                {fault}
            </Typography>

            {infoItems.map((item, index) => (
                <div key={index}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {item.icon}
                            <Typography variant="subtitle1" color="secondaryText" >
                                {item.label}
                            </Typography>
                        </Stack>
                        <Typography variant="subtitle1" fontWeight="700">{item.value}</Typography>
                    </Box>
                    {index < infoItems.length - 1 && <AppDivider inCard="true" />}
                </div>
            ))}
        </Stack>
    );
}

export default AlertInfo;
