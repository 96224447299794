import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton, InputAdornment, Stack, Typography} from "@mui/material";

import {WavingHandIcon} from '../../assets/Icons/Icons.js';
import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {fetchContractorData} from "../../services/api/registration/registrationApi";
import {RegistrationContext} from "../../contexts/RegistrationContext";

const phoneRegExp = /^\d{10}$/;

// TODO: Move this into dedicated utilities file in order to not have to declare every file. Re-do this file, modularity...
const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: values.userType === 'Contractor'
            ? Yup.string().matches(phoneRegExp, 'Phone Number must be exactly 10 digits, no characters').required('Phone number is required')
            : Yup.string().notRequired(),
        password: values.userType === 'Customer'
            ? Yup.string()
                .matches(
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/,
                    "Must contain 8 characters, one uppercase, one number and one special character"
                )
                .required('Password is required')
            : Yup.string().notRequired(),
        confirmPassword: values.userType === 'Customer'
            ? Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required')
            : Yup.string().notRequired(),
    });
});


function RegisterOne(props) {
    const api = useApi();
    const alert = useAlert();
    const [showInputs, setShowInputs] = useState(false);
    const { formState, setFormState, nextStep } = useContext(RegistrationContext);
    const { userType } = formState;
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => setShowPassword(!showPassword);

    const handleQuestion = (answer) => {
        const userType = answer ? 'Contractor' : 'Customer';
        setFormState(prevState => ({ ...prevState, userType }));
        setShowInputs(true);
    };

    const handleContinue = async (values) => {
        const sanitizedPhoneNumber = values.phoneNumber.replace(/\D/g, '');
        const updatedValues = { ...values, phoneNumber: sanitizedPhoneNumber };

        setFormState(prevState => ({ ...prevState, ...updatedValues }));

        if (userType === 'Contractor') {
            const userData = await fetchContractorData(api, updatedValues.email, sanitizedPhoneNumber);
            console.log(userData);
            if (userData && userData.status === 200) {
                if (userData.body.password) {
                    alert('Your account is already set up!', 'success');
                    return;
                }
                setFormState(prevState => ({
                    ...prevState,
                    firstName: userData.body.first_name,
                    lastName: userData.body.last_name,
                    email: userData.body.email,
                    phoneNumber: sanitizedPhoneNumber,
                    companyName: userData.body.company_id,
                    userType
                }));
                nextStep();
            } else {
                alert('This account was not found', 'error');
            }
        } else {
            nextStep();
        }
    };

    return (
        <>
            {!showInputs ? (
                <Stack alignItems="center" spacing={3}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <WavingHandIcon color="secondary" fontSize="large" />
                        <Typography variant="h6">Welcome to Anesi</Typography>
                    </Stack>
                    <Typography color="secondaryText" variant="h6" fontWeight="700">Are you a contractor?</Typography>
                    <Stack direction="row" width="100%" my={1.5}>
                        <AppButton  variant="secondary" onClick={() => handleQuestion(false)}>No</AppButton>
                        <AppButton variant="primary" onClick={() => handleQuestion(true)}>Yes</AppButton>
                    </Stack>
                    <Typography variant="body2">
                        Homeowners' / customers please choose no. You'll have the option to register on the next page.
                    </Typography>
                </Stack>
            ) : (
                <>
                    <Stack alignItems="center" spacing={1}>
                        <Typography textAlign="center"  color="secondaryText" variant="h6" >
                            {userType === 'Contractor' ? 'Ensure you have filled out your pre-registration form. If so, please provide your email and phone number.' : 'Create an Account'}
                        </Typography>
                        <Typography color="secondaryText" >
                            {userType === 'Contractor' ? '' : 'Creating an account takes only moments. Enter your email and create a password below.'}
                        </Typography>
                    </Stack>
                    <Formik initialValues={formState} validationSchema={validationSchema} onSubmit={handleContinue}>
                        <Form>
                            <Stack spacing={1} mt={1}>
                                <AppTextInput label="Email" name="email"/>
                                {userType === 'Contractor' && (
                                    <AppTextInput label="Phone Number" name="phoneNumber"/>
                                )}
                                {userType === 'Customer' && (
                                    <>
                                        <Typography textAlign="center">
                                            and
                                        </Typography>
                                        <AppTextInput
                                            type={showPassword ? "text" : "password"}
                                            label="Password"
                                            name="password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <AppTextInput
                                            type={showPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword}>
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            </Stack>
                            <Stack my={2}>
                                <AppButton variant="primary" type="submit">{userType === 'Contractor' ? 'Finish set up' : 'Create an account!'}</AppButton>
                                <Typography mt={1}  color="secondaryText" textAlign="center">
                                    Already have an account? <Link to="/login">Log in</Link>
                                </Typography>
                            </Stack>
                        </Form>
                    </Formik>
                </>
            )}
        </>
    );
}

export default RegisterOne;
