import React, {useContext, useState} from 'react';
import styled from "@emotion/styled";
import {Avatar, Box, DialogContentText, Stack, Typography} from "@mui/material";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Email, Person, Phone, Work} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

import AppTextInput from "../shared/AppTextInput";
import AppButton from "../shared/AppButton";
import AppDialog from "../shared/AppDialog";
import PhoneNumberInput from "../AppPhoneInput";
import AppCard from "../shared/AppCard";
import {RegistrationContext} from "../../contexts/RegistrationContext";

// TODO: Move this into dedicated utilities file in order to not have to declare every file. Re-do this file, modularity...

const InfoCard = styled(AppCard)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1em',
    position: 'relative',
    overflow: 'visible',
});

const AvatarIcon = styled(Avatar)({
    position: 'absolute',
    top: '-20px',
    right: '-10px',
    width: '50px',
    height: '50px',
    backgroundColor: '#4EB6B5',
    color: 'white',
    fontSize: '1.5em',
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    marginTop: '0.25em',
    marginBottom: '1em',
});

const InfoItem = ({ label, Icon, value }) => (
    <Box display="flex" alignItems="center" gap={2} >
        <Icon fontSize="small" color="secondary" />
        <Stack direction="column">
            <Typography variant="body2" color="gray">{label}</Typography>
            <Typography fontWeight="bold">{value}</Typography>
        </Stack>
    </Box>
);

function RegisterTwo() {
    const navigate = useNavigate();
    const { formState, setFormState, setStep } = useContext(RegistrationContext);
    const { userType } = formState;

    const [showOptInModal, setShowOptInModal] = useState(false);
    const [showSupport, setShowSupport] = useState(false);


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        phoneNumber: Yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX').required('Phone number is required'),
    });

    const handleContinue = (values) => {
        const { role } = formState;
        setFormState(prevState => ({...prevState, ...values, role}));

        if (userType === 'Contractor') {
            setStep(2);
        } else {
            setShowOptInModal(true);
        }
    };

    const handleModalClose = () => {
        setShowOptInModal(false);
        setStep(4);
    };

    return (
        <>
            {userType === 'Contractor' ? (
                <>
                    <Stack mb={2}>
                        <Typography variant="h5" >Hi, {formState.firstName}!</Typography>
                        <Typography color="secondaryText" >Does your information look correct?</Typography>
                    </Stack>
                    <InfoCard type="good">
                        <AvatarIcon src="/static/images/avatar/1.jpg" alt={formState.firstName} />
                        <Stack spacing={1}>
                            <InfoItem
                                label="Name"
                                Icon={Person}
                                value={`${formState.firstName} ${formState.lastName}`}
                            />
                            <InfoItem
                                label="Employer"
                                Icon={Work}
                                value={formState.companyName}
                            />
                            <InfoItem
                                label="Phone Number"
                                Icon={Phone}
                                value={formState.phoneNumber}
                            />
                            <InfoItem
                                label="Email"
                                Icon={Email}
                                value={formState.email}
                            />
                        </Stack>
                    </InfoCard>
                    <ButtonContainer flexDirection="column">
                        <AppButton buttonVariant="outlined" variant="secondary" onClick={() => setShowSupport(true)}>No</AppButton>
                        <AppButton onClick={() => setStep(2)}>Yes</AppButton>
                    </ButtonContainer>
                    <AppDialog
                        open={showSupport}
                        title="Support Needed"
                        handleClose={() => setShowSupport(false)}
                        handleConfirm={() => navigate('/')}
                        confirmText="Continue"
                    >
                        <DialogContentText fontWeight="600">
                            Please contact Anesi support for further assistance.
                        </DialogContentText>
                    </AppDialog>
                </>
            ) : (
                <>
                    <Stack alignItems="center" spacing={1}>
                        <Typography variant="h6">And you are?</Typography>
                        <Typography variant="subtitle1" color="secondaryText" textAlign="center">Finish by inputting some simple information.</Typography>
                    </Stack>
                    <Formik initialValues={formState} validationSchema={validationSchema} onSubmit={handleContinue}>
                        <Form>
                            <Stack direction="column" spacing={2} mb={2}>
                                <AppTextInput label="First Name" name="firstName"/>
                                <AppTextInput label="Last Name" name="lastName"/>
                                <PhoneNumberInput placeholder="Phone Number" />
                                <Typography variant="caption" >
                                    In the following step, you will have the option to opt in to receive SMS notifications from Anesi.
                                </Typography>
                            </Stack>
                            <AppButton variant="primary" type="submit">Continue</AppButton>
                        </Form>
                    </Formik>

                    <AppDialog
                        open={showOptInModal}
                        title="Opt in to SMS Notifications?"
                        handleClose={handleModalClose}
                        handleConfirm={handleModalClose}
                        confirmText="I agree"
                        cancelText="No"
                    >
                        <DialogContentText fontWeight="600">
                            By opting in, you agree to receive text messages from Anesi including:
                        </DialogContentText>
                        <ul>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Account updates</li>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Service alerts</li>
                            <li style={{ fontWeight: '500', fontFamily: 'Jura', fontSize: '0.95em', color: '#37589D'}}>Exclusive offers</li>
                        </ul>
                        <DialogContentText fontWeight="600">
                            Messages may vary in frequency depending on your activities and preferences. Standard message and
                            data rates may apply. You may reply STOP to unsubscribe at any time.
                        </DialogContentText>
                    </AppDialog>

                </>
            )}
        </>
    );
}

export default RegisterTwo;
