import React, {useContext, useState} from 'react';
import {Checkbox, CircularProgress, FormControlLabel, Stack, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";

import AppButton from "../shared/AppButton";
import AppAccordion from "../shared/AppAccordion";
import {useApi} from "../../contexts/ApiProvider";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../contexts/AlertsProvider";
import {registerUser} from "../../services/api/registration/registrationApi";
import {RegistrationContext} from "../../contexts/RegistrationContext";

function RegisterThree() {
    const navigate = useNavigate();
    const api = useApi();
    const alert = useAlert();
    const { formState, setFormState } = useContext(RegistrationContext);
    const [loading, setLoading] = useState(false);

    const handleFinish = async (values) => {
        setLoading(true);
        setFormState(prevState => ({...prevState, ...values}));

        let userData = {...formState};
        delete userData.confirmPassword;

        userData = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            password: userData.password,
            phone_number: userData.phoneNumber,
        };

        const response = await registerUser(api, userData);
        if (response.ok) {
            navigate('/login');
            alert('Successfully registered! Please log in.', 'success');
        } else if (response.status === 404) {
            alert('Your information is already in use', 'error')
        } else {
            alert('Could not create account', 'error')
        }

        setLoading(false);
    };

    return (
        <>
            <Stack alignItems="center" mb={2}>
                <Typography variant="h6">Almost Done</Typography>
                <Typography variant="subtitle1"  color="secondaryText">Review the Terms of Service and Privacy Policy</Typography>
            </Stack>
            <AppAccordion title="Terms of Service - Privacy Policy">
                <li style={{ fontFamily: 'Jura', fontSize: '0.9em', marginLeft: '1em' }}>
                    No Responsibility Clause: <br /><br />
                    By using our application, you agree to the following terms:
                    <ul >
                        <li>Our application is provided on an "as is" and "as available" basis without any warranties or representations, either express or implied.</li>
                        <li>We do not warrant that the application will be uninterrupted, error-free, or free from viruses or other harmful components.</li>
                        <li>We are not responsible for any damages or loss of data that may occur from the use of our application.</li>
                        <li>You acknowledge that the use of our application is at your own risk and discretion.</li>
                        <li>We reserve the right to modify or discontinue the application at any time without prior notice.</li>
                        <li>In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the application.</li>
                    </ul>
                </li>
            </AppAccordion>
            <Formik
                initialValues={formState}
                onSubmit={handleFinish}
            >
                {({ values }) => (
                    <Form>
                        <Field name="termsAccepted">
                            {({ field }) => (
                                <FormControlLabel
                                    style={{ marginTop: '1em' }}
                                    control={
                                        <Checkbox {...field} checked={!!field.value}  color="success" />
                                    }
                                    label={
                                        <Typography variant="caption" >
                                            I acknowledge that I have read and understand the Anesi Terms of Service and Privacy Policy.
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            )}
                        </Field>
                        <Stack direction="column" my={2}>
                            <div style={{ position: 'relative' }}>
                                <AppButton
                                    variant="primary"
                                    type="submit"
                                    disabled={!values.termsAccepted || loading}
                                    style={{ minWidth: '100px' }}
                                >
                                    Finish
                                </AppButton>
                                {loading &&
                                    <CircularProgress
                                        size={24}
                                        style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }}
                                    />
                                }
                            </div>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default RegisterThree;

