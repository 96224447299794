import React, { useState, useCallback } from 'react';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
    Card
} from "@mui/material";

import {MenuIcon, LocalPhoneIcon, EmailIcon, EngineeringIcon, OpenInNewIcon, ExpandMoreIcon} from '../../assets/Icons/Icons.js';
import AppContainer from "../../components/shared/AppContainer";
import AppButton from "../../components/shared/AppButton";
import Logo from '../../assets/Images/anesi_Logo.png';
import SMTI_logo from '../../assets/Images/smti_logo.png';
import Products_photo from '../../assets/Images/anesi_products.png';
import Divider from "@mui/material/Divider";

const DRAWER_WIDTH_MOBILE = '75%';
const DRAWER_WIDTH_DESKTOP = '300px';

const MainContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1em',
});

const ButtonsContainer = styled(Box)({
    marginBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
    gap: '.25em',
    width: '100%',
});

const LogoContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
    marginTop: '1em',
});

const BottomContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
        width: '90%',
        alignSelf: 'center'
    }
});

const StyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        backgroundColor: '#F5F5F5',
        borderRight: '2px solid #4EB6B5',
        width: DRAWER_WIDTH_MOBILE,
        '@media (min-width: 1024px)': {
            width: DRAWER_WIDTH_DESKTOP,
        },
    },
});

const ResponsiveContainer = styled(Box)({
    '@media (min-width:1024px)': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        overflow: 'hidden',
    }
});

// Drawer Content Component
function HomeDrawerContent({ handleNavigate }) {
    return (
            <List sx={{ padding: 1 }}>
                <Typography  color="secondaryText" variant="h6" align="center" m={1} >
                    Get in Touch
                </Typography>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton component="a" href="tel:423-735-7400">
                        <ListItemIcon>
                            <LocalPhoneIcon color="secondary" fontSize="small"  />
                        </ListItemIcon>
                        <ListItemText primary="Call Us" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="mailto:info@stonemtntechnologies.com">
                        <ListItemIcon>
                            <EmailIcon color="secondary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Email Us" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => handleNavigate('/contractor_form')}>
                        <ListItemIcon>
                            <EngineeringIcon color="secondary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Contractor Registration" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => window.open("https://stonemountaintechnologies.com", '_blank', 'noopener,noreferrer')}>
                        <ListItemIcon>
                            <OpenInNewIcon color="secondary" fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Learn about us" />
                    </ListItemButton>
                </ListItem>
            </List>
    );
}

function HomeScreen() {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const isTablet = useMediaQuery('(min-width:768px)');
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setDrawerOpen(prev => !prev);
    }, []);

    const handleNavigate = useCallback((path) => {
        navigate(path);
        if (drawerOpen) toggleDrawer();
    }, [navigate, drawerOpen, toggleDrawer]);

    return (
        <ResponsiveContainer>
            {!isDesktop && (
                <AppContainer justifyContent='space-between'>
                    <Box>
                        <IconButton
                            onClick={toggleDrawer}
                            aria-label="Open menu"
                        >
                            <MenuIcon fontSize="small" />
                        </IconButton>
                        <LogoContainer>
                            <img
                                style={{ width: isTablet ? '20%' : '30%' }}
                                src={Logo}
                                alt="Anesi Logo"
                                loading="lazy"
                            />
                        </LogoContainer>
                    </Box>

                    <MainContainer>
                        <img
                            src={Products_photo}
                            alt="Anesi products"
                            style={{ width: isTablet ? '50%' : '65%' }}
                            loading="lazy"
                        />
                    </MainContainer>

                    <BottomContainer>
                        <ButtonsContainer>
                            <AppButton variant="primary" onClick={() => handleNavigate('/login')}>
                                Sign-in
                            </AppButton>
                            <AppButton variant="secondary" onClick={() => handleNavigate('/register')}>
                                Register
                            </AppButton>
                        </ButtonsContainer>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
                            <Typography variant="caption">By Stone Mountain Technologies, Inc.</Typography>
                            <img
                                style={{ width: "5vw" }}
                                src={SMTI_logo}
                                alt="SMTI Logo"
                                loading="lazy"
                            />
                        </Stack>
                    </BottomContainer>
                </AppContainer>
            )}

            {isDesktop && (
                <Box position="absolute" top="1em" left="1em">
                    <IconButton onClick={toggleDrawer} aria-label="Open menu">
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </Box>
            )}

            {isDesktop && (
                <Stack width="45%" spacing={3}>
                    <LogoContainer>
                        <img
                            src={Logo}
                            alt="Anesi Logo"
                            style={{ width: '15%', marginBottom: '1em', alignSelf: 'center' }}
                            loading="lazy"
                        />
                    </LogoContainer>
                    <Card
                        elevation={0}
                        sx={{
                            padding: '30px',
                            borderRadius: '16px',
                            boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                            border: '1px solid rgba(145, 158, 171, 0.1)'
                        }}
                    >
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img
                                src={Products_photo}
                                alt="Anesi products"
                                style={{ width: '45%', marginBottom: '3em', marginTop: '2.5em' }}
                                loading="lazy"
                            />
                            <Box width="85%">
                                <AppButton
                                    variant="primary"
                                    onClick={() => handleNavigate('/login')}
                                    fullWidth
                                >
                                    Sign-in
                                </AppButton>
                                <AppButton
                                    variant="secondary"
                                    onClick={() => handleNavigate('/register')}
                                    fullWidth
                                >
                                    Register
                                </AppButton>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap="0.5em"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    mt="0.5em"
                                >
                                    <Typography fontWeight="600" fontSize=".75em">
                                        By Stone Mountain Technologies, Inc.
                                    </Typography>
                                    <img
                                        style={{ width: "5%" }}
                                        src={SMTI_logo}
                                        alt="SMTI Logo"
                                        loading="lazy"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Stack>
            )}

            <StyledDrawer anchor="left" open={drawerOpen} onClose={toggleDrawer} >
                <Stack justifyContent="space-between" height="100%">
                    <HomeDrawerContent handleNavigate={handleNavigate} />
                    <Stack direction="row" alignItems="center" spacing={0.5} m={1}>
                        <Typography variant="body2" color="secondaryText">
                            English
                        </Typography>
                        <ExpandMoreIcon fontSize="small" />
                    </Stack>
                </Stack>
            </StyledDrawer>

        </ResponsiveContainer>
    )
}

export default HomeScreen;
