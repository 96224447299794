import React, {useState} from 'react';
import styled from '@emotion/styled';
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";

import AppContainer from "../shared/AppContainer";
import AppButton from "../shared/AppButton";
import AppHeader from "../shared/AppHeader";

const StyledFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        fontWeight: '700',
        color: 'grey',
    },
});

const CustomRadio = styled(Radio)({
    color: '#37589D',
    '&.Mui-checked': {
        color: '#4EB6B5',
    },
});

const applicationMapping = {
    'Forced-Air (w/Anesi AHU)': 0,
    'Hydronic (radiant floor)': 1,
    'Commercial water heating': 2,
    'Pool heating': 3,
    'Ice melt': 4,
};

function AddUnitOne(props) {
    const [selected, setSelected] = useState('');
    const handleChange = (ev) => {
        setSelected(ev.target.value);
        props.onChoiceChange(applicationMapping[ev.target.value]);
    };

    return (
        <AppContainer style = {{ width: '100%' }}>
            <AppHeader title="Add Unit" backButtonRoute={`/dashboard/my_units`} />
            <Typography  textAlign="center" variant="h6" mt={1} color="secondaryText.main" >
                Select the Application
            </Typography>
            <Box m={1}>
                <FormControl>
                    <RadioGroup onChange={handleChange}>
                        <StyledFormControlLabel value="Forced-Air (w/Anesi AHU)" control={<CustomRadio />} label="Forced-Air (w/Anesi AHU)" />
                        <StyledFormControlLabel value="Commercial water heating" control={<CustomRadio />} label="Commercial water heating" />
                        <StyledFormControlLabel disabled={true} value="Hydronic (radiant floor)" control={<CustomRadio />} label="Hydronic (radiant floor)" />
                        <StyledFormControlLabel disabled={true} value="Pool heating" control={<CustomRadio />} label="Pool heating" />
                        <StyledFormControlLabel disabled={true} value="Ice melt" control={<CustomRadio />} label="Ice melt" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <AppButton onClick={props.onNext} disabled={!selected}>
                Continue
            </AppButton>
        </AppContainer>
    );
}

export default AddUnitOne;
