import React, {useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {useLocation, useNavigate} from "react-router-dom";

import {
    Box,
    Typography,
    IconButton,
    Card, CardContent, Collapse, CardHeader, Stack
} from "@mui/material";

import {AddBoxIcon, SearchIcon, Inventory2Icon, WorkspacesIcon, CheckIcon, MoreVertIcon, WarningIcon, ExpandMoreIcon} from '../../assets/Icons/Icons.js';
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import AppCard from "../../components/shared/AppCard";
import AppLoader from "../../components/shared/AppLoader";
import AppDrawer from "../../components/shared/AppDrawer";
import {UnitInfoContent} from "../../components/unitSpecific/UnitDrawerContent";
import UnitCard from "../../components/unitSpecific/UnitCard";
import AppSearchBar from "../../components/shared/AppSearchBar";
import {useUnits} from "../../contexts/UnitProvider";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {useUser} from "../../contexts/UserProvider";
import FilterMenu from "../../components/units/FilterMenu";

const StyledInvCard = styled(AppCard)(({ faultInUnits }) => ({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: faultInUnits ? '#B1494A' : '#4EB6B5',
    border: 'none',
    color: '#FFFFFF',
    marginTop: '1em',
    marginBottom: '0.5em',
}));

const GradientCard = styled(Card)({
    background: 'linear-gradient(to right, #37589D, #4EB6B5)',
    color: 'white',
    borderRadius: '10px',
    padding: '0.75em',
    margin: '1em 0',
    cursor: 'pointer',
    boxShadow: 'none'
});

const getFullAddress = (unit) => {
    if (unit && unit.users) {
        const owner = unit.users.find((user) => user.role === 0);
        if (owner && owner.addresses && owner.addresses[0]) {
            const addr = owner.addresses[0];
            return `${addr.address_line_1}${addr.address_line_2 ? ', ' + addr.address_line_2 : ''}, ${addr.city}, ${addr.state_abbreviation} ${addr.zipcode}`;
        }
    }
    return 'Not available';
};

const getInstallLocationAddress = (unit) => {
    if (unit && unit.install_location_id && unit.install_location) {
        const addr = unit.install_location;
        return `${addr.address_line_1}${addr.address_line_2 ? ', ' + addr.address_line_2 : ''}, ${addr.city}, ${addr.state_abbreviation} ${addr.zipcode}`;
    }
    return 'Not available';
};

const filterUnits = (units, term, filter) => {
    const lowerTerm = term.toLowerCase();
    return units.filter((unit) => {
        const matchesSerialNumber = unit.serial_number.toLowerCase().includes(lowerTerm);
        const matchesName = unit.name_customer && unit.name_customer.toLowerCase().includes(lowerTerm);
        const matchesFilter =
            filter === 'all' ||
            (filter === 'inFault' && unit.faults && unit.faults.length > 0) ||
            (filter === 'operational' && (!unit.faults || unit.faults.length === 0));

        return (matchesSerialNumber || matchesName) && matchesFilter;
    });
};

const getUniqueContractors = (units) => {
    const uniqueContractors = new Map();
    units.forEach((unit) => {
        const contractor = unit.users.find((user) => user.role !== 0);
        if (contractor && !uniqueContractors.has(contractor.id)) {
            uniqueContractors.set(contractor.id, contractor);
        }
    });
    return Array.from(uniqueContractors.values());
};

function UnitsScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const {user, isContractor} = useUser();
    const alert = useAlert();
    const api = useApi();
    const {units, fetchAllUnits, fetchUnitGroups} = useUnits();

    const [hasUnits, setHasUnits] = useState(false);
    const [hasUnitGroups, setHasUnitGroups] = useState(false);
    const [faultInUnits, setFaultInUnits] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUnits, setFilteredUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [cardExpanded, setCardExpanded] = useState(false);

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);  // For MoreVert menu
    const [unitFilter, setUnitFilter] = useState('all');  // For filtering units

    const fullAddress = getFullAddress(selectedUnit);
    const installLocationAddress = getInstallLocationAddress(selectedUnit);

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};
    const handleToolTip = () => {setTooltipOpen(!tooltipOpen)};
    const handleExpandClick = () => {setCardExpanded(!cardExpanded)};

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleFilterSelection = (filter) => {
        setUnitFilter(filter);
        handleFilterClose();
    };

    // same with this, see below
    useEffect(() => {
        const fetchUnits = async () => {
            setLoading(true);
            const response = await fetchAllUnits();
            if(response.ok) {
                if (response.body && response.body.length > 0) {
                    setHasUnits(true);
                }
            } else {
                navigate('/dashboard');
                alert('Enable to fetch units at this time', 'error');
            }
            setLoading(false);
        };
        fetchUnits();
    }, []);

    // This may be redundant, if fetchunitgroups is auto called when file mounts from unit provider
    const fetchGroups = async () => {
        setLoading(true);
        const response = await fetchUnitGroups();
        if (response.ok) {
            if (response.body && response.body.length > 0) {
                setHasUnitGroups(true);
            }
        } else if (response.status === 404) {
            setHasUnitGroups(false);
        } else {
            setHasUnitGroups(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGroups();
    }, []);

    useEffect(() => {
        if (location.state) {
            if (location.state.filter) {
                setUnitFilter(location.state.filter);
            }

            if (location.state.expandCard) {
                setCardExpanded(true);
            } else {
                setCardExpanded(false);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (Array.isArray(units)) {
            const anyFaults = units.some(unit => Array.isArray(unit.faults) && unit.faults.length > 0);
            setFaultInUnits(anyFaults);
            // filter and search can be combined...
            const term = searchTerm.toLowerCase();
            const filtered = units.filter(unit => {
                const matchesSerialNumber = unit.serial_number.toLowerCase().includes(term);
                const matchesName = unit.name_customer && unit.name_customer.toLowerCase().includes(term);
                const matchesFilter = unitFilter === 'all'
                    || (unitFilter === 'inFault' && unit.faults && unit.faults.length > 0)
                    || (unitFilter === 'operational' && (!unit.faults || unit.faults.length === 0));

                return (matchesSerialNumber || matchesName) && matchesFilter;
            });

            setFilteredUnits(filtered);
            setCardExpanded(searchTerm !== '' || location.state?.expandCard === true);
        }
    }, [searchTerm, units, unitFilter, location.state]);


    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    // Find better way to do this. Does not re-fetch on page refresh.
    const getUniqueContractors = (units) => {
        const uniqueContractors = new Set();
        const contractors = [];

        units.forEach(unit => {
            const contractor = unit.users.find(user => user.role !== 0);
            if (contractor && !uniqueContractors.has(contractor.id)) {
                uniqueContractors.add(contractor.id);
                contractors.push(contractor);
            }
        });
        return contractors;
    };

    // this can be moved to unitsProvider, same on other files
    const handleRemoveUnit = async (unitId) => {
        const data = {units: [unitId]}
        const response = await api.delete('/me/units', data );
        if (response.ok) {
            console.log(`Unit with ID ${unitId} successfully deleted.`);
            await fetchAllUnits();
        } else {
            console.error('Failed to delete the unit.');
        }
    };

    if (loading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard`} title="Installed Units" rightIcon={<AddBoxIcon />} onClickRight={() => navigate(`add_unit`)} />
            {hasUnits ? (
                <>
                    <Typography mt={2} variant="subtitle1" textAlign="center" color="secondaryText" >
                        {isContractor ? 'Select a unit for comprehensive data or swipe for quick actions.' : 'Choose your unit for more information.'}
                    </Typography>
                    <AppSearchBar
                        placeholder="Search units..."
                        endAdornment={<SearchIcon color="secondary" />}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <StyledInvCard faultInUnits={faultInUnits}>
                        <Stack direction="row" alignItems="center" spacing={2} p={0.5} justifyContent="space-evenly">
                            {faultInUnits ? (
                                <>
                                    <Typography fontWeight="bold">Some units need attention</Typography>
                                    <WarningIcon />
                                </>
                            ) : (
                                <>
                                    <Typography fontWeight="bold">All units are operational</Typography>
                                    <CheckIcon />
                                </>
                            )}
                        </Stack>
                    </StyledInvCard>
                    <Card variant="outlined" style={{ borderRadius: '10px', marginTop: '0.5em', position: 'relative', backgroundColor: cardExpanded ? 'white' : '#FCFCFF' }}>
                        <CardHeader style={{ padding: '10px' }}
                            avatar={ <Inventory2Icon color="success" style={{ fontSize: '1.5em' }} /> }
                            title={
                                <Typography fontWeight="700" color="#959292">
                                    {isContractor ? 'All Units' : 'My Equipment'}
                                </Typography>
                            }
                            subheader="Associated with me"
                            action={
                                <>
                                    <ExpandMore
                                        expand={cardExpanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={cardExpanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                    <IconButton sx={{ padding: 0 }} onClick={handleFilterClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <FilterMenu
                                        anchorEl={filterAnchorEl}
                                        open={Boolean(filterAnchorEl)}
                                        onClose={handleFilterClose}
                                        onSelect={handleFilterSelection}
                                    />
                                </>
                            }
                        />
                        <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
                            <CardContent style={{ maxHeight: '12em', overflowY: 'auto'}}>
                                <Stack spacing={1}>
                                    {filteredUnits && filteredUnits.map((unit) => (
                                        <UnitCard
                                            key={unit.id}
                                            unit={unit}
                                            contractor={isContractor}
                                            navigate={navigate}
                                            source="units"
                                            showInfo={true}
                                            setDrawerOpen={setDrawerOpen}
                                            setSelectedUnit={setSelectedUnit}
                                            fetchAllUnits={fetchAllUnits}

                                            onInfoClick={() => {
                                                setSelectedUnit(unit);
                                                setDrawerOpen(true);
                                            }}

                                            onSwipe={() => handleRemoveUnit(unit.id)}
                                        />
                                    ))}
                                </Stack>
                            </CardContent>
                        </Collapse>
                    </Card>
                    {!isContractor && (
                        <Card sx={{ backgroundColor: '#37589D', color: 'white', marginTop: '1em', borderRadius: '10px' }}>
                            <CardHeader
                                title={
                                    <Typography fontWeight="700" color="white">
                                        My Contractors
                                    </Typography >
                                }
                            />
                            <CardContent>
                                <Stack spacing={1}>
                                    {getUniqueContractors(filteredUnits).map((contractor) => (
                                        <Box key={contractor.id}>
                                            <Typography fontWeight="600">
                                                {contractor.first_name} {contractor.last_name}
                                            </Typography>
                                            <Typography>
                                                Phone: {contractor.phone_numbers.length > 0 ? contractor.phone_numbers[0].number : 'N/A'}
                                            </Typography>
                                            <Typography>
                                                Email: {contractor.email}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Stack>
                            </CardContent>
                        </Card>
                    )}
                    {isContractor && (
                        <GradientCard onClick={() => navigate('groups')}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <WorkspacesIcon />
                                <Stack>
                                    <Typography fontWeight="700">
                                        My Groups
                                    </Typography>
                                    <Typography variant="body2">
                                        Manage and organize your groups
                                    </Typography>
                                </Stack>
                            </Stack>
                        </GradientCard>
                    )}
                </>
            ) : (
                <Typography textAlign="center" color="secondaryText" fontWeight="700">
                    There are no units currently installed. Choose the '+' in the upper right to add.
                </Typography>
            )}

            <AppDrawer open={drawerOpen} onClose={handleDrawer} title="Quick Info">
                <UnitInfoContent
                    selectedUnit={selectedUnit}
                    loggedInUser={{ id: user.id, role: user.role }}
                    handleToolTip={handleToolTip}
                    tooltipOpen={tooltipOpen}
                    fullAddress={fullAddress}
                    installAddress={installLocationAddress}
                />
            </AppDrawer>

        </AppContainer>
    );
}

export default UnitsScreen;

