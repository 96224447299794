// import {Navigate, Route, Routes, useLocation} from "react-router-dom";
// import {AnimatePresence} from "framer-motion";
// import PublicRoute from "../components/navigation/PublicRoute";
// import HomeScreen from "../views/HomeScreen";
// import AppTransition from "../components/shared/AppTransition";
// import LogInScreen from "../views/LogInScreen";
// import RegisterScreen from "../views/RegisterScreen";
// import PasswordResetScreen from "../views/PasswordResetScreen";
// import PasswordResettingScreen from "../views/PasswordResettingScreen";
// import PrivateRoute from "../components/navigation/PrivateRoute";
// import DashboardScreen from "../views/DashboardScreen";
// import SettingsScreen from "../views/SettingsScreen";
// import AccountScreen from "../views/AccountScreen";
// import UnitsScreen from "../views/UnitsScreen";
// import AddUnitScreen from "../views/AddUnitScreen";
// import UnitViewScreen from "../views/UnitViewScreen";
// import ContractorDataScreen from "../views/ContractorDataScreen";
// import DocumentsScreen from "../views/DocumentsScreen";
// import AlertsScreen from "../views/AlertsScreen";
// import ServicingAssistantScreen from "../views/ServicingAssistantScreen";
// import PlotsScreen from "../views/PlotsScreen";
// import AppContractorForm from "../components/registration/AppContractorForm";
// import GroupsScreen from "../views/GroupsScreen";
//
// const AppRoutes = () => {
//     const location = useLocation();
//
//     return (
//         <AnimatePresence mode="wait">
//             <Routes location={location} key={location.pathname}>
//                 <Route path="/" element={
//                     <PublicRoute><HomeScreen /></PublicRoute>} />
//                 <Route path="/login" element={
//                     <PublicRoute><AppTransition><LogInScreen /></AppTransition></PublicRoute>} />
//                 <Route path="/register" element={
//                     <PublicRoute><AppTransition><RegisterScreen /></AppTransition></PublicRoute>} />
//                 <Route path="/contractor_form" element={
//                     <PublicRoute><AppTransition><AppContractorForm /></AppTransition></PublicRoute>} />
//                 <Route path="/password_reset" element={
//                     <PublicRoute><AppTransition><PasswordResetScreen /></AppTransition></PublicRoute>} />
//                 <Route path="/reset" element={
//                     <PublicRoute><AppTransition><PasswordResettingScreen /></AppTransition></PublicRoute>} />
//                 <Route path="/dashboard/*" element={
//                     <PrivateRoute>
//                         <Routes>
//                             <Route index element={<DashboardScreen />} />
//                             <Route path="settings" element={<AppTransition><SettingsScreen /></AppTransition>} />
//                             <Route path="my_account" element={<AppTransition><AccountScreen /></AppTransition>} />
//                             <Route path="my_units/*" element={
//                                 <Routes>
//                                     <Route index element={<AppTransition><UnitsScreen /></AppTransition>} />
//                                     <Route path="groups" element={<AppTransition><GroupsScreen /></AppTransition>} />
//                                     <Route path="add_unit" element={<AppTransition><AddUnitScreen /></AppTransition>} />
//                                     <Route path=":unitId/*" element={
//                                         <Routes>
//                                             <Route index element={<AppTransition><UnitViewScreen /></AppTransition>} />
//                                             <Route path="more-data" element={<AppTransition><ContractorDataScreen /></AppTransition>} />
//                                             <Route path="documents" element={<AppTransition><DocumentsScreen /></AppTransition>} />
//                                             <Route path="alerts" element={<AppTransition><AlertsScreen /></AppTransition>} />
//                                             <Route path="assistant" element={<AppTransition><ServicingAssistantScreen /></AppTransition>} />
//                                             <Route path="plot" element={<AppTransition><PlotsScreen /></AppTransition>} />
//                                         </Routes>
//                                     }/>
//                                 </Routes>} />
//                         </Routes>
//                     </PrivateRoute>
//                 } />
//                 <Route path="*" element={<Navigate to="/" />} />
//             </Routes>
//         </AnimatePresence>
//     );
// }
//
// export default AppRoutes;

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTheme, useMediaQuery } from "@mui/material";
import PublicRoute from "../components/navigation/PublicRoute";
import HomeScreen from "../views/public/HomeScreen";
import AppTransition from "../components/shared/AppTransition";
import LogInScreen from "../views/public/LogInScreen";
import RegisterScreen from "../views/public/RegisterScreen";
import PasswordResetScreen from "../views/public/PasswordResetScreen";
import PasswordResettingScreen from "../views/public/PasswordResettingScreen";
import PrivateRoute from "../components/navigation/PrivateRoute";
import DashboardScreen from "../views/private/DashboardScreen";
import SettingsScreen from "../views/private/SettingsScreen";
import AccountScreen from "../views/private/AccountScreen";
import UnitsScreen from "../views/private/UnitsScreen";
import AddUnitScreen from "../views/private/AddUnitScreen";
import UnitViewScreen from "../views/private/UnitViewScreen";
import ContractorDataScreen from "../views/private/ContractorDataScreen";
import DocumentsScreen from "../views/private/DocumentsScreen";
import AlertsScreen from "../views/private/AlertsScreen";
import ServicingAssistantScreen from "../views/private/ServicingAssistantScreen";
import PlotsScreen from "../views/private/PlotsScreen";
import AppContractorForm from "../components/registration/AppContractorForm";
import GroupsScreen from "../views/private/GroupsScreen";

const AppRoutes = () => {
    const location = useLocation();
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

    const withTransition = (element) => {
        if (prefersReducedMotion) {
            return element;
        }
        return isMobileOrTablet ? <AppTransition>{element}</AppTransition> : element;
    };

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/"
                    element={
                        <PublicRoute>
                            {withTransition(<HomeScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/login"
                    element={
                        <PublicRoute>
                            {withTransition(<LogInScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/register"
                    element={
                        <PublicRoute>
                            {withTransition(<RegisterScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/contractor_form"
                    element={
                        <PublicRoute>
                            {withTransition(<AppContractorForm />)}
                        </PublicRoute>
                    }
                />
                <Route path="/password_reset"
                    element={
                        <PublicRoute>
                            {withTransition(<PasswordResetScreen />)}
                        </PublicRoute>
                    }
                />
                <Route path="/reset"
                    element={
                        <PublicRoute>
                            {withTransition(<PasswordResettingScreen />)}
                        </PublicRoute>
                    }
                />

                <Route path="/dashboard/*"
                    element={
                        <PrivateRoute>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        withTransition(<DashboardScreen />)
                                    }
                                />
                                <Route path="settings"
                                    element={withTransition(<SettingsScreen />)}
                                />
                                <Route path="my_account"
                                    element={withTransition(<AccountScreen />)}
                                />
                                <Route path="my_units/*"
                                    element={
                                        <Routes>
                                            <Route index element={withTransition(<UnitsScreen />)}/>
                                            <Route path="groups" element={withTransition(<GroupsScreen />)}/>
                                            <Route path="add_unit" element={withTransition(<AddUnitScreen />)}/>
                                            <Route path=":unitId/*"
                                                element={
                                                    <Routes>
                                                        <Route index element={withTransition(<UnitViewScreen />)}/>
                                                        <Route path="more-data" element={withTransition(<ContractorDataScreen />)}/>
                                                        <Route path="documents" element={withTransition(<DocumentsScreen />)}/>
                                                        <Route path="alerts" element={withTransition(<AlertsScreen />)}/>
                                                        <Route path="assistant" element={withTransition(<ServicingAssistantScreen />)}/>
                                                        <Route path="plot" element={withTransition(<PlotsScreen />)}/>
                                                    </Routes>
                                                }
                                            />
                                        </Routes>
                                    }
                                />
                            </Routes>
                        </PrivateRoute>
                    }
                />
                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AppRoutes;
