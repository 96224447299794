import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import styled from "@emotion/styled";

import {useUnits} from "../../contexts/UnitProvider";
import {useUser} from "../../contexts/UserProvider";
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import UnitPlot from "../../components/unitSpecific/UnitPlot";
import AppCard from "../../components/shared/AppCard";

const MainContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 5,
    },
    backgroundColor: '#4EB6B5',
    borderColor: 'transparent',
    color: '#FFFFFF',
    marginTop: '1em'
});

const titleToRegisterMap = {
    "Hydronic Supply": 12,
    "Hydronic Return": 16,
    "Ambient": 10,
    "Hydronic Flow": 17,
    "Supply Air": 42,
    "Mid Tank": 20
};

function PlotsScreen(props) {
    const {unitId} = useParams();
    const { isContractor } = useUser();
    const {unit} = useUnits();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const plotTitle = params.get('title') || "Unit Plot";
    const plotRegister = titleToRegisterMap[plotTitle]
    // if (!plotRegister) {
    //     console.log('Can not get register on plot screen')
    // }

    if (!unit) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`}/>
                <Typography textAlign="center" fontWeight="600">Plot data can not be accessed at this time.</Typography>
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute={`/dashboard/my_units/${unitId}`}/>
            <UnitHeader unit={isContractor ? unit.name_contractor : unit.name_customer} sn={unit.serial_number} />
            <MainContainer>
                <UnitPlot
                    unitId={unitId}
                    plotRegisterNumber={plotRegister}
                    title={plotTitle}
                />
            </MainContainer>
            <StyledCard>
                <Typography fontWeight="bold" textAlign="center">
                    Fetching live data...
                </Typography>
            </StyledCard>
        </AppContainer>
    );
}

export default PlotsScreen;
