import { useState } from 'react';
import {TextField, Typography} from '@mui/material';
import AppDialog from '../shared/AppDialog';
import {useNavigate} from "react-router-dom";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";

const PasswordDialog = ({ open, onClose, onPasswordSubmit }) => {
    const api = useApi();
    const alert = useAlert();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = (ev) => {
        setPassword(ev.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await api.post('/admin_login', { admin_password: password });
            if (response.ok) {
                onPasswordSubmit();
                onClose();
            } else {
                alert('Incorrect password. Please try again.');
            }
        } catch (error) {
            console.log('Error submitting password:', error);
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <AppDialog
            open={open}
            title="Welcome!"
            handleConfirm={handleSubmit}
            handleClose={handleCancel}
            confirmText="Continue"
            cancelText="Cancel"
        >
            <Typography mb={2} variant="subtitle2">
                Please enter your session password to continue. If you have any questions, please contact Anesi support.
            </Typography>
            <TextField
                type="password"
                label="Password"
                variant="standard"
                fullWidth={true}
                value={password}
                onChange={handlePasswordChange}
                error={Boolean(error)}
                helperText={error}
                InputProps={{
                    sx: {
                        '&:after': {
                            borderBottomColor: '#4EB6B5',
                        },
                    },
                }}
                InputLabelProps={{
                    sx: {
                        '&.Mui-focused': {
                            color: '#37589D',
                            fontWeight: 'bold',
                        },
                    },
                }}
            />
        </AppDialog>
    );
};

export default PasswordDialog;
