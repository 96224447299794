import {Box, IconButton, Stack, Typography} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";

import {PictureAsPdfIcon, ChevronRightIcon, QuizIcon} from '../../assets/Icons/Icons.js'
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import DocumentLink from "../../components/documents/DocumentLink";

const documents = [
    {
        title: "Owner's Manual",
        icon: <PictureAsPdfIcon fontSize="large" color="secondary" />,
        url: 'https://stonemountaintechnologies.com/resources/technical-library/#manuals',
    },
    {
        title: 'Warranty',
        icon: <PictureAsPdfIcon fontSize="large" color="secondary" />,
        url: 'https://stonemountaintechnologies.com/warranty/',
    },
    {
        title: 'Product Specifications',
        icon: <PictureAsPdfIcon fontSize="large" color="secondary" />,
        url: 'https://stonemountaintechnologies.com/resources/technical-library/#datasheets',
    },
    {
        title: 'FAQs',
        icon: <QuizIcon fontSize="large" color="secondary" />,
        url: 'https://stonemountaintechnologies.com/faq/',
    },
];

function DocumentsScreen() {
    const { unitId } = useParams();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/${unitId}?source=groups&groupId=${groupId}`
        : `/dashboard/my_units/${unitId}?source=${source}`;


    const openAllResources = () => {
        window.open("https://stonemountaintechnologies.com/resources/", '_blank', 'noopener,noreferrer');
    };

    return (
        <AppContainer>
            <AppHeader backButtonRoute={backButtonRoute} title="Documents" />
            <Stack mt={2.5} >
                <Stack mb={5} spacing={2}>
                    {documents.map((doc) => (
                        <DocumentLink key={doc.title} title={doc.title} icon={doc.icon} url={doc.url} />
                    ))}
                </Stack>
                <Stack  >
                    <Typography variant="subtitle1" color="secondaryText" align="left" gutterBottom>
                        See entire collection
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography variant="subtitle1" color='secondary' fontWeight="600">All Resources</Typography>
                        <IconButton onClick={openAllResources}>
                            <ChevronRightIcon color="secondary" />
                        </IconButton>
                    </Box>
                </Stack>
            </Stack>
        </AppContainer>
    );
}

export default DocumentsScreen;
