import {Box, IconButton, Stack, Typography} from "@mui/material";

import {ChevronRightIcon} from "../../assets/Icons/Icons";
import AppDivider from "../shared/AppDivider";

const DocumentLink = ({ title, icon, url }) => {
    const openDoc = () => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Stack>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {icon}
                    <Typography fontWeight="bold" color="secondary" variant="subtitle1" ml={2}>{title}</Typography>
                </Stack>
                <IconButton onClick={openDoc}>
                    <ChevronRightIcon color="secondary"  />
                </IconButton>
            </Box>
            <AppDivider />
        </Stack>
    );
};

export default DocumentLink;
