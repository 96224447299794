
// Fetch contractor data
export const fetchContractorData = async (apiClient, email, phoneNumber) => {
    try {
        return await apiClient.post('/con_registration', {
            email: email,
            phone_number: phoneNumber,
        });
    } catch (error) {
        console.error('Error fetching contractor user data:', error);
    }
};

// Send 2FA code
export const send2FACode = async (apiClient, phoneNumber) => {
    try {
        return await apiClient.post('/create_2fa', {
            phone_number: phoneNumber,
        });
    } catch (error) {
        console.error('Error sending 2FA code:', error);
    }
};

// Verify 2FA code
export const verify2FACode = async (apiClient, phoneNumber, verificationCode) => {
    try {
        return await apiClient.post('/verify_2fa', {
            phone_number: phoneNumber,
            verification_code: verificationCode,
        });
    } catch (error) {
        console.error('Error verifying 2FA code:', error);
    }
};

// Set Contractor password
export const updateContractorPassword = async (apiClient, password) => {
    try {
        return await apiClient.put('/con_registration', password);
    } catch (error) {
        console.error('Error updating contractor password:', error);
    }
};

// Register new user (Customer or Contractor)
export const registerUser = async (apiClient, userData) => {
    try {
        const response = await apiClient.post('/users', userData);
        console.log(response);
        return response;
    } catch (error) {
        console.error('Error registering user:', error);
    }
};

