import React, { useState, useEffect, useRef } from 'react';
import styled from "@emotion/styled";
import {Box, Stack, TextField, Typography, useMediaQuery} from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";

import {QrCode2Icon} from '../../assets/Icons/Icons.js'
import AppContainer from "../shared/AppContainer";
import AppHeader from "../shared/AppHeader";
import AppCard from "../shared/AppCard";
import AppButton from "../shared/AppButton";

const StyledCard = styled(AppCard)({
    borderColor: '#4EB6B5',
});

const ScannerContainer = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
});

function AddUnitTwo(props) {
    const [serialSelected, setSerialSelected] = useState(false);
    const [serialNum, setSerialNum] = useState('');
    const [scanning, setScanning] = useState(false);
    const [isScannerStarting, setIsScannerStarting] = useState(false);
    const [isScannerStopping, setIsScannerStopping] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:359px)');
    const html5QrcodeScannerRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:480px)');

    const handleScanClick = () => {
        if (!isScannerStarting && !isScannerStopping) {
            setIsScannerStarting(true);
            setScanning(true);
        }
    };

    const handleSerSelect = () => {
        setSerialSelected(true);
    };

    const handleSerInput = (ev) => {
        setSerialNum(ev.target.value);
        props.onSerialChange(ev.target.value);
    };

    const stopAndClearScanner = () => {
        if (!html5QrcodeScannerRef.current || isScannerStopping) return;

        setIsScannerStopping(true);
        html5QrcodeScannerRef.current
            .stop()
            .then(() => html5QrcodeScannerRef.current.clear())
            .then(() => {
                html5QrcodeScannerRef.current = null;
                console.log("Scanner stopped and cleared.");
            })
            .catch((err) => {
                console.error("Error stopping the scanner", err);
            })
            .finally(() => {
                setIsScannerStopping(false);
                setIsScannerStarting(false);
                setScanning(false);
            });
    };

    const handleCloseScan = () => {
        if (!isScannerStopping) {
            stopAndClearScanner();
        }
    };

    useEffect(() => {
        if (scanning) {
            const onScanSuccess = (decodedText) => {
                const serialNumber = decodedText.split('sn=')[1]?.split('&')[0];
                setSerialNum(serialNumber);
                props.onSerialChange(serialNumber);
                stopAndClearScanner();
            };

            const onScanFailure = (error) => {
                console.log(`Code scan error = ${error}`);
            };

            html5QrcodeScannerRef.current = new Html5Qrcode("reader");

            Html5Qrcode.getCameras()
                .then((devices) => {
                    const rearCamera = devices.find((device) =>
                        device.label.toLowerCase().includes('back') ||
                        device.label.toLowerCase().includes('rear')
                    );
                    const cameraId = rearCamera ? rearCamera.id : devices[0].id;

                    html5QrcodeScannerRef.current
                        .start(
                            cameraId,
                            {
                                fps: 10,
                                qrbox: 250,
                            },
                            onScanSuccess,
                            onScanFailure
                        )
                        .then(() => {
                            setIsScannerStarting(false);
                        })
                        .catch((err) => {
                            console.error('Error starting the scanner', err);
                            setIsScannerStarting(false);
                            setScanning(false);
                        });
                })
                .catch((err) => {
                    console.error('Error getting cameras:', err);
                    setIsScannerStarting(false);
                    setScanning(false);
                });
        }

        return () => {
            stopAndClearScanner();
        };
    }, [scanning]);

    return (
        <AppContainer style={{ width: '100%' }}>
            {/*This on back is used for the multi context navigation, that is why it's different from other pages.*/}
            <AppHeader title="Add Unit" onBack={props.onBack} />
            <Typography textAlign="center" variant="h6" mt={1} color="secondaryText.main" >How would you like to register the unit?</Typography>
            <Stack spacing={1} mt={2}>
                {!scanning && (
                    <>
                        {isMobile && (
                            <StyledCard onClick={handleScanClick}>
                                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                    <Typography fontWeight="bold" variant="h5">
                                        Scan
                                    </Typography>
                                    <QrCode2Icon fontSize="large" />
                                </Stack>
                            </StyledCard>
                        )}

                        {/* Input Serial Number Option */}
                        <StyledCard onClick={handleSerSelect}>
                            {(serialSelected || serialNum !== '') ? (
                                <TextField
                                    label="Enter Serial Number:"
                                    fullWidth
                                    variant="standard"
                                    value={serialNum}
                                    onChange={handleSerInput}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: isSmallScreen ? '0.9em' : '1em',
                                            fontWeight: '600',
                                        },
                                    }}
                                />
                            ) : (
                                <Typography fontWeight="bold" variant="h5" textAlign="center">
                                    Input Serial Number
                                </Typography>
                            )}
                        </StyledCard>
                    </>
                )}
                {scanning && (
                    <ScannerContainer>
                        <div id="reader" style={{ width: '100%' }}></div>
                        <Typography
                            color="white"
                            align="center"
                            fontSize="1.5em"
                            style={{ marginTop: '1em' }}
                        >
                            Scanning QR Code...
                        </Typography>
                        <AppButton
                            customStyles={{ marginTop: '1em' }}
                            onClick={handleCloseScan}
                            disabled={isScannerStarting || isScannerStopping}
                        >
                            Close
                        </AppButton>
                    </ScannerContainer>
                )}
            </Stack>

            {/* Submit Button */}
            {!scanning && (
                <AppButton
                    customStyles={{ marginTop: '2em' }}
                    onClick={props.onSubmit}
                    disabled={serialNum === ''}
                >
                    Submit
                </AppButton>
            )}
        </AppContainer>
    );
}

export default AddUnitTwo;
