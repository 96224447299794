import React from "react";
import styled from "@emotion/styled";
import {Menu, MenuItem} from "@mui/material";
import Divider from "@mui/material/Divider";
import {FilterListIcon, ErrorIcon, CheckCircleIcon} from '../../assets/Icons/Icons.js';

const StyledMenu = styled(Menu)({
    '& .MuiPaper-root': {
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    '& .MuiMenuItem-root': {
        fontSize: '14px',
        fontWeight: '600',
        padding: '4px 10px',
        '&:hover': {
            backgroundColor: '#e0e0e5',
        },
    },
    '& .MuiDivider-root': {
        backgroundColor: 'ghostwhite',
        margin: 0,
    },
});

function FilterMenu({ anchorEl, open, onClose, onSelect }) {
    return (
        <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            <MenuItem onClick={() => onSelect('all')} sx={{ justifyContent: 'space-between' }}>
                All
                <FilterListIcon color="secondary" fontSize="small" sx={{ ml: 1 }} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => onSelect('inFault')} sx={{ justifyContent: 'space-between' }}>
                In Fault
                <ErrorIcon fontSize="small" sx={{ ml: 1 }} color="error" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => onSelect('operational')} sx={{ justifyContent: 'space-between' }}>
                Operational
                <CheckCircleIcon fontSize="small" sx={{ ml: 1 }} color="success" />
            </MenuItem>
        </StyledMenu>
    );
}

export default React.memo(FilterMenu);
