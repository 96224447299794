import React, { useEffect, useState } from 'react';
import AppDialog from '../shared/AppDialog';
import {
    TextField,
    Typography,
    IconButton,
    Grid,
    Divider,
    Checkbox,
    FormControlLabel, Stack,
} from '@mui/material';
import {EditIcon, ChevronRightIcon, ArrowBackIosIcon} from '../../assets/Icons/Icons.js';


function EditParentDialog({ open, handleClose, parentGroup, handleSave, allGroups }) {
    const [parentGroupName, setParentGroupName] = useState('');
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingSubgroups, setIsEditingSubgroups] = useState(false);
    const [groupsToAdd, setGroupsToAdd] = useState([]);
    const [groupsToRemove, setGroupsToRemove] = useState([]);
    const [currentSubgroups, setCurrentSubgroups] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);

    useEffect(() => {
        if (parentGroup) {
            setParentGroupName(parentGroup.name);
            setCurrentSubgroups(parentGroup.groups);

            const subGroupIds = parentGroup.groups.map((g) => g.id);
            const available = allGroups.filter((g) => !subGroupIds.includes(g.id));
            setAvailableGroups(available);
        }
    }, [parentGroup, allGroups]);

    const toggleGroupToAdd = (groupId) => {
        setGroupsToAdd((prev) =>
            prev.includes(groupId) ? prev.filter((id) => id !== groupId) : [...prev, groupId]
        );
    };

    const toggleGroupToRemove = (groupId) => {
        setGroupsToRemove((prev) =>
            prev.includes(groupId) ? prev.filter((id) => id !== groupId) : [...prev, groupId]
        );
    };

    const handleConfirm = () => {
        handleSave(parentGroupName, groupsToAdd, groupsToRemove);
    };

    const handleEditNameClick = () => {
        setIsEditingName(true);
    };

    const handleEditSubgroupsClick = () => {
        setIsEditingSubgroups(true);
    };

    const handleBackToMain = () => {
        setIsEditingSubgroups(false);
    };

    return (
        <AppDialog
            open={open}
            title={
                isEditingSubgroups ? (
                    <Stack direction="row" alignItems="center">
                        <IconButton size="small" onClick={handleBackToMain} edge="start">
                            <ArrowBackIosIcon color="secondary" />
                        </IconButton>
                        <Typography variant="h6">
                            Edit Subgroups
                        </Typography>
                    </Stack>
                ) : (
                    'Edit this Group'
                )
            }
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            confirmText="Save"
            cancelText="Cancel"
        >
            {isEditingSubgroups ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Check and un-check to add / remove groups.
                        </Typography>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Current Subgroups:</Typography>
                        <Divider sx={{ mb: 1 }} />
                        {currentSubgroups.length > 0 ? (
                            currentSubgroups.map((group) => (
                                <FormControlLabel
                                    key={group.id}
                                    control={
                                        <Checkbox
                                            checked={!groupsToRemove.includes(group.id)}
                                            onChange={() => toggleGroupToRemove(group.id)}
                                            size="small"
                                            color="success"
                                        />
                                    }
                                    label={group.name}
                                />
                            ))
                        ) : (
                            <Typography variant="body2">No subgroups were found.</Typography>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Available Groups to Add:</Typography>
                        <Divider sx={{ mb: 1 }} />
                        {availableGroups.length > 0 ? (
                            availableGroups.map((group) => (
                                <FormControlLabel
                                    key={group.id}
                                    control={
                                        <Checkbox
                                            checked={groupsToAdd.includes(group.id)}
                                            onChange={() => toggleGroupToAdd(group.id)}
                                            size="small"
                                            color="success"
                                        />
                                    }
                                    label={group.name}
                                />
                            ))
                        ) : (
                            <Typography variant="body2">None available.</Typography>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography mb={1} variant="subtitle2">
                            You can edit this groups name or continue and edit the nested sub groups.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {isEditingName ? (
                            <TextField
                                variant="standard"
                                InputProps={{ disableUnderline: true }}
                                value={parentGroupName}
                                onChange={(e) => setParentGroupName(e.target.value)}
                                fullWidth
                            />
                        ) : (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">{parentGroupName}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleEditNameClick}>
                                        <EditIcon fontSize="small" color="secondary" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={handleEditSubgroupsClick}
                            style={{ cursor: 'pointer' }}
                        >
                            <Typography variant="subtitle1">Edit Subgroups</Typography>
                            <IconButton edge="end" onClick={handleEditSubgroupsClick}>
                                <ChevronRightIcon color="secondary" />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </AppDialog>
    );
}

export default EditParentDialog;
