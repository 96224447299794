import React, { useEffect, useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import {useUser} from "../../contexts/UserProvider";

const tourSteps = [
    {
        target: 'body',
        content: 'Welcome to your Anesi Dashboard! Let’s begin!',
        placement: 'center',
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        target: '.my-account-button',
        content: 'Your account page gives you control of your personal information.',
        placement: 'bottom',
        disableBeacon: true,
    },
    {
        target: '.settings-button',
        content: 'Here you can edit your application preferences.',
        placement: 'bottom',
    },
    {
        target: '.clickable-card',
        content: 'View all your units and related information here.',
        placement: 'bottom',
    },
    {
        target: '.snapshot',
        content: 'Get your status snapshot here.',
        placement: 'bottom',
    },
];

function DashboardTour() {
    const {user} = useUser();
    const [runTour, setRunTour] = useState(false);

    useEffect(() => {
        const hasTakenTour = localStorage.getItem('hasTakenTour') === 'true';
        if (!hasTakenTour) {
            setRunTour(true);
        }
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.setItem('hasTakenTour', 'true');
            setRunTour(false);
        }
    };

    return (
        <Joyride
            continuous
            run={runTour}
            steps={tourSteps}
            callback={handleJoyrideCallback}
            scrollToFirstStep
            showProgress
            showSkipButton
            hideCloseButton="true"
            styles={{
                options: {
                    primaryColor: '#4EB6B5',
                    fontFamily: 'Jura',
                    textColor: '#37589D',
                    arrowColor: '#4EB6B5',
                },
                tooltip: {
                    backgroundColor: '#fff',
                    borderRadius: '0.75em',
                },
                tooltipContent: {
                    fontFamily: 'Jura',
                    fontWeight: '700',
                },
                buttonNext: {
                    backgroundColor: '#4EB6B5',
                    border: '1px',
                    borderColor: 'transparent',
                },
                buttonBack: {
                    color: '#37589D',
                    fontFamily: 'Jura',
                    fontWeight: '700',
                    marginRight: '0.75em',
                },
                buttonSkip: {
                    fontFamily: 'Jura'
                },
            }}
        />
    );
}

export default DashboardTour;
