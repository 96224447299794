import React from 'react';
import {Box, Card, Icon, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {ExpandLessIcon} from '../../assets/Icons/Icons.js';


function GroupCard({ groupName, nUnits, onClick }) {
    return (
        <Card variant="outlined" sx={{ borderRadius: '10px' }} onClick={onClick}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ padding: '8px', backgroundColor: 'secondary.main', color: 'white' }}>
                <Typography variant="subtitle2" fontWeight="700">
                    {groupName}
                </Typography>
                <Icon fontSize="small">
                    <ExpandLessIcon />
                </Icon>
            </Box>
            <Divider />
            <Box sx={{ padding: '8px' }}>
                <Typography variant="body2">
                    {nUnits} unit{nUnits !== 1 ? 's' : ''}
                </Typography>
            </Box>
        </Card>
    );
}

export default GroupCard;
