import React from 'react';
import {Typography, IconButton, Stack} from "@mui/material";

import {ThumbUpAltIcon, LocalPhoneIcon} from '../../assets/Icons/Icons.js';
import FaultItem from "./FaultItem";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} style={{  maxHeight: '55vh', overflowY: 'auto'}} {...other}>
            {value === index && (
                <Stack spacing={1} mt={2}>
                    {children}
                </Stack>
            )}
        </div>
    );
}

function AlertsTabPanel({ value, index, faults, isContractor, onFaultClick, emptyMessage, showThumbsUp = false, showContactInfo = false })
{
    return (
        <TabPanel value={value} index={index}>
            {faults.length > 0 ? (
                faults.map((fault) => (
                    <FaultItem key={fault.id} fault={fault} isContractor={isContractor} onFaultClick={onFaultClick} />
                ))
            ) : (
                <Stack alignItems="center" spacing={2} mt={2}>
                    <Typography textAlign="center" fontWeight="bold" color="secondaryText">
                        {emptyMessage}
                    </Typography>
                    {showThumbsUp && (
                        <ThumbUpAltIcon
                            fontSize="large"
                            color="success"
                        />
                    )}
                </Stack>
            )}

            {showContactInfo && (
                <Stack direction="row" alignItems="center" spacing={2}> mt={1}>
                    <IconButton>
                        <LocalPhoneIcon color="secondary" />
                    </IconButton>
                    <Typography ml={1} color="secondaryText" >
                        Contact your Anesi contractor.
                    </Typography>
                </Stack
                  >
            )}

        </TabPanel>
    );
}

export default AlertsTabPanel;
