import React, {useState} from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import {CircularProgress, Stack, Typography} from "@mui/material";
import AppTextInput from "../../components/shared/AppTextInput";
import AppButton from "../../components/shared/AppButton";
import {useApi} from "../../contexts/ApiProvider";
import {useAlert} from "../../contexts/AlertsProvider";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
});

function PasswordResetScreen(props) {
    const api = useApi();
    const alert = useAlert();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await api.post('/tokens/reset', values);
        if (response.ok) {
            alert('You will receive an email with instructions', 'success');
        } else if (response.status === 404) {
            alert('Email address was not found', 'error')
        } else {
            alert('There was an error with your request', 'error')
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader title="Password Reset" backButtonRoute="/login"/>
            <Stack textAlign="center" spacing={2} my={1.5}>
                <Typography variant="h6" >Need Password Help? </Typography>
                <Typography variant="subtitle2" color="secondaryText">
                    Enter the email associated with your account and we’ll send you instructions to reset your password.
                </Typography>
            </Stack>
            <Formik initialValues={{ email: ''}} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <AppTextInput style={{ width: '100%'}} name="email" label="Email"  type="email" placeholder="me@email.com"/>
                    <div style={{ position: 'relative' }}>
                        <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>Request</AppButton>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                    </div>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResetScreen;

