import React from 'react';
import styled from '@emotion/styled';
import {Box} from "@mui/material";
import AlertMessage from "./AlertMessage";


// TODO: Remove use of this component and just use mui container instead.
//  Or just wrap main app.js and remove from every other file, or use index.js globally.... low priority.

const Container = styled(Box)(({ justifyContent = 'flex-start' }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent,
    minHeight: '100vh',
    boxSizing: 'border-box',
    position: 'relative',
}));

const AppContainer = ({ fullwidth, children, ...otherProps }) => {
    const styles = {
        padding: fullwidth ? '0' : '1em',
        margin: fullwidth ? '0' : '0.1em',
    };
    return (
        <Container style={styles} {...otherProps}>
            <AlertMessage />
            {children}
        </Container>
    );
};

export default AppContainer;


