import React, { createContext, useState } from 'react';

export const RegistrationContext = createContext();

export const RegistrationProvider = ({ children }) => {
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAccepted: false,
        phoneNumber: '',
        company: '',
        userType: null,
        address: '',
        role: 0,
    });

    const [step, setStep] = useState(0);

    const nextStep = () => setStep((prevStep) => prevStep + 1);
    const prevStep = () => setStep((prevStep) => prevStep - 1);
    const goToStep = (stepNumber) => setStep(stepNumber);

    return (
        <RegistrationContext.Provider value={{ formState, setFormState, step, setStep, nextStep, prevStep, goToStep }}>
            {children}
        </RegistrationContext.Provider>
    );
};
