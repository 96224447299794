import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {CircularProgress, Stack, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import AppContainer from "../../components/shared/AppContainer";
import AppTextInput from "../../components/shared/AppTextInput";
import AppButton from "../../components/shared/AppButton";
import {useAlert} from "../../contexts/AlertsProvider";
import {useApi} from "../../contexts/ApiProvider";
import AppHeader from "../../components/shared/AppHeader";

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Must contain 8 characters, one uppercase, one number and one special character"
        )
        .required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
});

function PasswordResettingScreen(props) {
    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    const handleSubmit = async (values) => {
        setLoading(true);
        const submitData = {
            reset_token: token,
            new_password: values.confirmPassword,
        };
        const response = await api.put('/tokens/reset', submitData);
        if (response.ok) {
            alert('Your password has been reset', 'success');
            navigate('/login');
        } else {
            alert('Password could not be reset. Please try again', 'error');
            navigate('/password_reset');
        }
        setLoading(false);
    }

    return (
        <AppContainer>
            <AppHeader backButtonRoute="/" />
            <Typography variant="h6" color="secondaryText" my={1.5}>
                Please fill out the fields below and you will be all set.
            </Typography>
            <Formik initialValues={{ password: '', confirmPassword: ''}} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                    <Stack spacing={2}>
                        <AppTextInput  name="password" label="Password" type="password" placeholder="Password"/>
                        <AppTextInput  name="confirmPassword" label="Password Again"  type="password" placeholder="Confirm Password"/>
                        <div style={{ position: 'relative' }}>
                            <AppButton disabled={loading} variant="primary" type="submit" style={{ marginTop: '1em' }}>Reset Password</AppButton>
                            {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '60%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                        </div>
                    </Stack>
                </Form>
            </Formik>
        </AppContainer>
    );
}

export default PasswordResettingScreen;
