import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
    Typography,
    Box,
    Chip,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Divider,
} from '@mui/material';
import {FiberManualRecordIcon, ArrowForwardIosSharpIcon} from '../../assets/Icons/Icons.js'
import RemoteAccessIcon from "../../assets/Icons/RemoteAccessIcon";
import { useUser } from "../../contexts/UserProvider";
import { useUnits } from "../../contexts/UnitProvider";
import { convertAndDetermineColor } from "../../utilities/TempUtils";
import { CustomNameUtils } from "../../utilities/CustomNameUtils";
import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import UnitController from "../../components/unitSpecific/Control/UnitController";
import AppCard from "../../components/shared/AppCard";
import AppLoader from "../../components/shared/AppLoader";


const Accordion = styled(MuiAccordion)(({ theme }) => ({
    border: `1px solid #4EB6B5`,
    boxShadow: 'none',
}));

const AccordionDetailsStyled = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,

}));

const NestedAccordion = styled(MuiAccordion)(({ theme }) => ({
    boxShadow: 'none',
    border: 'none',
    '&:before': {
        display: 'none',
    },
}));

const NestedAccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.8rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
    },
}));

const NestedAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: `1px solid white`, // Set border color to white
}));

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 10,
    },
});

const CardTitle = styled(Typography)({
    marginBottom: '0.25em',
    fontWeight: '600',
    color: '#959292',
});

function ContractorDataScreen(props) {
    const { user, isContractor } = useUser();
    const { unitId } = useParams();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const navigate = useNavigate();
    const { unit, unitType, unitData, getSpecificUnitData, fetchUnitLink } = useUnits();
    const [hasFault, setHasFault] = useState();
    const isMetric = user?.metric;
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/${unitId}?source=groups&groupId=${groupId}`
        : `/dashboard/my_units/${unitId}?source=${source}`;

    // State for nested accordions expansion
    const [nestedExpanded, setNestedExpanded] = useState(false);
    const handleNestedChange = (panel) => (event, newExpanded) => {
        setNestedExpanded(newExpanded ? panel : false);
    };

    // Fetching data, can move loading state to context file and avoid here
    const fetchData = useCallback(async () => {
        try {
            await getSpecificUnitData(unitId, true);
            setIsLoading(false);
            setHasError(false);
        } catch (error) {
            setIsLoading(false);
            setHasError(true);
        }
    }, [unitId, getSpecificUnitData]);

    useEffect(() => {
        fetchData();  // Initial data fetch
        const interval = setInterval(fetchData, 10000);
        return () => clearInterval(interval);
    }, [fetchData]);

    useEffect(() => {
        if (!isContractor) {
            navigate('/dashboard');
        } else {
            setHasFault(unit?.faults && unit?.faults === true);
        }
    }, [unit, isContractor, navigate]);

    if (isLoading) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    }

    // Better ways to handle this... UnitData issues can be resolved by handling how it is saved in the unitProvider fetch function...
    if (hasError || !unit || unitData === null || (Array.isArray(unitData) && unitData.length === 0)) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units`} />
                <Typography fontWeight="700" fontSize="1.25em" align="center" color="#959292">
                    Oops, looks like the connection was dropped.
                </Typography>
            </AppContainer>
        );
    }

    const StatusIndicator = ({ isOn }) => (
        <Chip
            icon={<FiberManualRecordIcon style={{ color: isOn ? '#4EB6B5' : '#B1494A' }} />}
            label={
                <Typography component="span" style={{ fontWeight: '600' }}>
                    {isOn ? 'On' : 'Off'}
                </Typography>
            }
            variant="outlined"
            size="small"
            sx={{ marginLeft: '0.5em'}}
        />
    );

    const renderAccordions = () => {
        return (
            <Box mb={1} >
                <CardTitle>
                    Unit Condition:
                </CardTitle>

                {/* Parent Accordion without a title */}
                <Accordion>
                    <AccordionDetailsStyled>
                        {/* First Nested Accordion */}
                        <NestedAccordion expanded={nestedExpanded === 'nestedPanel1'} onChange={handleNestedChange('nestedPanel1')}>
                            <NestedAccordionSummary aria-controls="nestedPanel1d-content" id="nestedPanel1d-header">
                                <Typography fontWeight="600">
                                    {unitType === 'GAHP' ? 'Thermostat Inputs' : 'Thermostat Inputs and Outputs'}
                                </Typography>
                            </NestedAccordionSummary>
                            <NestedAccordionDetails>
                                {/* Content for the first dropdown */}
                                {unitType === 'GAHP' && unitData && unitData.slice(0, 6).map((item, index) => {
                                    const isOn = item[0] === 1;
                                    const registerName = item[1];

                                    return (
                                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Typography fontWeight="600">{registerName}</Typography>
                                            <StatusIndicator isOn={isOn} />
                                        </Box>
                                    );
                                })}
                                {unitType === 'AHU' && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Box>
                                            {unitData && unitData.slice(0, 9).map((item, index) => {
                                                const isOn = item[0] === 1;
                                                const registerName = item[1];
                                                return (
                                                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                        <Typography fontWeight="600">{registerName}</Typography>
                                                        <StatusIndicator isOn={isOn} />
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                        <Box>
                                            {unitData && unitData.slice(15, 23).map((item, index) => {
                                                const isOn = item[0] === 1;
                                                const registerName = item[1];
                                                return (
                                                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                        <Typography fontWeight="600">{registerName}</Typography>
                                                        <StatusIndicator isOn={isOn} />
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                )}
                            </NestedAccordionDetails>
                        </NestedAccordion>
                        <Divider />
                        <NestedAccordion expanded={nestedExpanded === 'nestedPanel2'} onChange={handleNestedChange('nestedPanel2')}>
                            <NestedAccordionSummary aria-controls="nestedPanel2d-content" id="nestedPanel2d-header">
                                <Typography fontWeight="600">
                                    {unitType === 'GAHP' ? 'Relays' : 'All Temperatures'}
                                </Typography>
                            </NestedAccordionSummary>
                            <NestedAccordionDetails>
                                {unitType === 'GAHP' && [46, 52, 40, 44, 50, 32, 76].map(index => {
                                    const item = unitData[index];
                                    const registerName = item[1];
                                    return (
                                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Typography fontWeight="600">{registerName}</Typography>
                                            {index === 76 ? (
                                                <Typography fontWeight="600">{item[0]} %</Typography>
                                            ) : (
                                                <StatusIndicator isOn={item[0] === 1} />
                                            )}
                                        </Box>
                                    );
                                })}
                                {unitType === 'AHU' && [45, 47, 48, 42, 41, 40, 43].sort((a, b) => a - b).map(index => {
                                    const item = unitData[index];
                                    let registerName = item[1].replace(/.*? - /, "").replace(/(Temperature|Teperature)\s*$/, "").trim();
                                    const registerValue = item[0];
                                    const { convertedValue, color } = convertAndDetermineColor(registerValue, isMetric);
                                    return (
                                        <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Typography fontWeight="600">{registerName}</Typography>
                                            <Typography fontWeight="600" style={{ color }}>{convertedValue}</Typography>
                                        </Box>
                                    );
                                })}
                            </NestedAccordionDetails>
                        </NestedAccordion>
                    </AccordionDetailsStyled>
                </Accordion>
            </Box>
        );
    };

    const openRemoteAccess = () => {
        const newWindow = window.open('about:blank', '_blank');
        fetchUnitLink(unitId).then(link => {
            if (link) {
                newWindow.location.href = link;
            } else {
                newWindow.close();
            }
        });
    };

    return (
        <AppContainer>
            <AppHeader
                backButtonRoute={backButtonRoute}
                title="Unit Controller"
                rightIcon={(user.role === 2 || user.role === 3 || user.role === 4) && <RemoteAccessIcon size="30" color="#37589D" />}
                onClickRight={openRemoteAccess}
            />
            <UnitHeader
                unit={CustomNameUtils(unit, isContractor)}
                sn={unit.serial_number}
                status={hasFault ? 'ERROR' : 'OK'}
            />

            {renderAccordions()}

            {unitType === 'AHU' && (
                <StyledCard type="good">
                    <Box display="flex" alignItems="center">
                        <Typography fontWeight="600" style={{ marginRight: '.5em' }}>Blower PWM:</Typography>
                        <Typography fontWeight="700">{unitData[74][0]} %</Typography>
                    </Box>
                </StyledCard>
            )}

            <Box mt={1}>
                <UnitController unitData={unitData} unitType={unitType} />
            </Box>
        </AppContainer>
    );
}

export default ContractorDataScreen;
