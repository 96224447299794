import React, {useCallback, useEffect, useState} from 'react';
import styled from "@emotion/styled";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Box, Stack, TextField, Typography} from "@mui/material";

import AppContainer from "../../components/shared/AppContainer";
import AppHeader from "../../components/shared/AppHeader";
import UnitHeader from "../../components/unitSpecific/UnitHeader";
import {OpenInFullIcon} from '../../assets/Icons/Icons.js';

import AppLoader from "../../components/shared/AppLoader";
import UnitStatus from "../../components/unitSpecific/UnitStatus";
import ModelViewer from "../../components/ModelViewer";
import AppDrawer from "../../components/shared/AppDrawer";
import BottomNav from "../../components/navigation/BottomNav";

import {useUnits} from "../../contexts/UnitProvider";
import {useAlert} from "../../contexts/AlertsProvider";
import {useUser} from "../../contexts/UserProvider";
import {CustomNameUtils} from "../../utilities/CustomNameUtils";
import {useApi} from "../../contexts/ApiProvider";
import {editUnitName} from "../../services/api/unit/unitApi";

const UnitContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '1em',
    boxSizing: 'border-box',
    margin: '0.1em',
    flex: '1 0 auto',
    overflowY: 'auto',
});

// use custom component
const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-input': {
        padding: '0.25em 1em',
        fontWeight: '700',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#4EB6B5',
            borderRadius: '1em',
            borderWidth: '1px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#4EB6B5',
        },
    },
});

function UnitViewScreen() {
    const alert = useAlert();
    const {user, isContractor} = useUser();
    const api = useApi();
    const {unitId} = useParams();
    const [searchParams] = useSearchParams();
    const [hasFault, setHasFault] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {unit, unitType, setUnitType, unitData, getSpecificUnitData} = useUnits();
    const [navValue, setNavValue] = useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [editName, setEditName] = useState({
        editing: false,
        unitName: '',
    });
    const source = searchParams.get('source') || 'units';
    const groupId = searchParams.get('groupId');

    const backButtonRoute = source === 'groups' && groupId
        ? `/dashboard/my_units/groups?groupId=${groupId}`
        : '/dashboard/my_units';


    // const backButtonRoute = source === 'groups'
    //     ? '/dashboard/my_units/groups'
    //     : '/dashboard/my_units';

    const handleDrawer = () => {setDrawerOpen(!drawerOpen)};

    const handleNavChange = useCallback((event, newValue) => {
        setNavValue(newValue);
        const sourceParam = searchParams.get('source') || 'units';
        const groupId = searchParams.get('groupId');
        const currentPath = `/dashboard/my_units/${unitId}`;
        let url = '';

        switch (newValue) {
            case 0:
                url = `${currentPath}/more-data?source=${sourceParam}`;
                break;
            case 1:
                url = `${currentPath}?source=${sourceParam}`;
                break;
            case 2:
                url = `${currentPath}/documents?source=${sourceParam}`;
                break;
            case 3:
                url = `${currentPath}/alerts?source=${sourceParam}`;
                break;
            default:
                break;
        }

        if (sourceParam === 'groups' && groupId) {
            url += `&groupId=${groupId}`;
        }
        navigate(url);


    }, [navigate, unitId, searchParams]);

    // much better ways of doing this in the unit provider hooks file, remove redundancy...
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getSpecificUnitData(unitId);
            if (response.ok) {
                const fetchedUnit = response.body.unit;
                setHasFault(fetchedUnit.faults && fetchedUnit.faults === true);
                const determinedUnitType = fetchedUnit.unit_model.name;
                setUnitType(determinedUnitType);
            } else if (response.status === 404) {
                alert('Access Denied: Account is not owner of this unit', 'error', 5);
                navigate(backButtonRoute);
            }
            setLoading(false);
        };
        fetchData();
        const interval = setInterval(() => {
            getSpecificUnitData(unitId, true);
        }, 3000);
        return () => {
            clearInterval(interval);
        };
    }, [unitId, getSpecificUnitData, setHasFault, setUnitType]);

    if (loading || unit === undefined) {
        return (
            <AppContainer justifyContent="center">
                <AppLoader />
            </AppContainer>
        );
    } else if (unitData === null || unit === null || unitType === undefined) {
        return (
            <AppContainer>
                <AppHeader backButtonRoute={`/dashboard/my_units`} />
                <Typography mt={1} variant="h6" textAlign="center" color="secondaryText"> Data for this unit is currently unavailable. Please try again in a bit.</Typography>
            </AppContainer>
        );
    }

    // can be moved to unit hook file eventually...
    const changeName = async () => {
        const { editing, unitName } = editName;
        if (!editing) {
            setEditName({
                editing: true,
                unitName: CustomNameUtils(unit, isContractor),
            });
            return;
        }

        const trimmedName = unitName.trim();
        if (!trimmedName) {
            setEditName({
                editing: false,
                unitName: CustomNameUtils(unit, isContractor),
            });
            return;
        }

        const nameData = {
            unit_id: unitId,
            unit_name: trimmedName,
        };

        try {
            const response = await editUnitName(api, nameData);
            if (response.ok) {
                await getSpecificUnitData(unitId);
            } else {
                alert('Failed to update the unit name', 'error');
            }
        } catch (error) {
            console.error('Error updating unit name:', error);
        }
        setEditName(prevState => ({
            ...prevState,
            editing: false,
        }));
    };


    return (
        <AppContainer fullwidth>
            <UnitContainer>
                <AppHeader title={editName.editing ? (
                    <StyledTextField onChange={(e) => setEditName({ ...editName, unitName: e.target.value })} autoFocus placeholder={CustomNameUtils(unit, isContractor)} />
                ) : (CustomNameUtils(unit, isContractor))} backButtonRoute={backButtonRoute} rightText={editName.editing ? 'Save' : 'Edit'} onClickRight={changeName} />
                <UnitHeader sn={unit.serial_number} status={hasFault ? 'ERROR' : 'OK'} />
                <Stack direction="row">
                    <ModelViewer src={`/models/${unitType.toLowerCase()}_model_${isContractor ? 'contractor' : 'customer'}.glb`} />
                    <OpenInFullIcon color="secondary" fontSize="small" onClick={handleDrawer} />
                </Stack>
                <UnitStatus unitType={unitType} unitData={unitData} contractor={isContractor} isMetric={user.metric} />
            </UnitContainer>
            <Box style={{ width: '100%' }}>
                <BottomNav
                    navValue={navValue}
                    onChange={handleNavChange}
                    hasFault={hasFault}
                    isContractor={isContractor}
                />
            </Box>

            <AppDrawer title="Your Unit" open={drawerOpen} onClose={handleDrawer}>
                    <ModelViewer src={`/models/${unitType.toLowerCase()}_model_${isContractor ? 'contractor' : 'customer'}.glb`} large={true} />
            </AppDrawer>

        </AppContainer>
    );
}

export default UnitViewScreen;

