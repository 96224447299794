import {InfoOutlinedIcon} from "../../assets/Icons/Icons";
import styled from "@emotion/styled";

import {Stack, Typography} from "@mui/material";
import AppCard from "../../components/shared/AppCard";

const StyledCard = styled(AppCard)({
    '& .MuiCardContent-root': {
        padding: 12,
    }
});

function FaultItem({ fault, isContractor, onFaultClick }) {
    return (
        <StyledCard key={fault.id} type={fault.active ? 'bad' : undefined}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>{fault.name}</Typography>
                {isContractor && (
                    <InfoOutlinedIcon
                        style={{ color: fault.active ? '#B1494A' : '#4EB6B5', cursor: 'pointer' }}
                        onClick={() => onFaultClick(fault)}
                    />
                )}
            </Stack>
        </StyledCard>
    );
}

export default FaultItem;
