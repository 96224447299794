import React from 'react';
import styled from "@emotion/styled";
import {Dialog, DialogContent, DialogActions, Typography, Slide, DialogTitle, IconButton} from "@mui/material";
import {CloseIcon, CheckIcon} from '../../assets/Icons/Icons.js';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} timeout={400}/>;
});

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        borderRadius: 15,
        alignItems: 'center',
        backgroundColor: '#F2F2F2'
    },
});

const StyledDialogActions = styled(DialogActions)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '65%',
});

const TopRightIconButton = styled(IconButton)({
    position: 'absolute',
    right: '5px',
    top: '5px',
});

function AppPop({title, children, handleOpen, handleClose, showGenericClose = false, showActions = false, handleConfirm, confirmationState, onGenericClose}) {
    return (
        <StyledDialog fullWidth open={handleOpen} TransitionComponent={Transition}>
            {showGenericClose && (
                <TopRightIconButton onClick={onGenericClose}>
                    <CloseIcon fontSize="small" />
                </TopRightIconButton>
            )}
            {title && (
                <DialogTitle align="center">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                    {children}
            </DialogContent>
            {showActions && (
                <StyledDialogActions>
                    {confirmationState === 'confirm' && (
                        <IconButton onClick={handleClose} style={{ flexDirection: 'column' }}>
                            <CloseIcon style={{ color: '#B1494A'}} fontSize="large" />
                            <Typography  fontWeight="700" style={{ fontSize: '0.75rem', color: '#959292' }}>
                                Cancel
                            </Typography>
                        </IconButton>
                    )}
                    <IconButton onClick={confirmationState === 'confirm' ? handleConfirm : handleClose} style={{ flexDirection: 'column' }}>
                        {confirmationState === 'confirm' ? <CheckIcon fontSize="large" style={{ color: '#4EB6B5'}} /> : <CheckIcon fontSize="large" style={{ color: '#4EB6B5'}} />}
                        <Typography fontWeight="700" style={{ fontSize: '0.75rem', color: '#959292' }}>
                            {confirmationState === 'confirm' ? 'Confirm' : 'Close'}
                        </Typography>
                    </IconButton>
                </StyledDialogActions>
            )}
        </StyledDialog>
    );
}

export default AppPop;
